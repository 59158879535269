@charset "UTF-8";
/* private */
@font-face {
  font-family: zwippi-font;
  src: url(fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: zwippi-light-font;
  src: url(fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: alt-font;
  src: url(fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: alt-bold-font;
  src: url(fonts/Montserrat-Bold.ttf);
}

h1 {
  color: #eee;
  font-family: alt-font;
  font-size: 36px;
  padding: 0px;
  text-transform: none;
}

h1 a {
  color: #eee;
}

h2 {
  color: #fff;
  font-family: alt-bold-font;
  font-size: 22px;
}

h2 a {
  color: #eee;
}

h3 {
  color: #eee;
  font-family: alt-font;
  font-size: 18px;
}

h3 a {
  color: #eee;
}

h4 {
  color: #eee;
  font-family: zwippi-light-font;
  font-size: 22px;
}

h5 {
  color: #eee;
  font-family: alt-font;
  font-size: 22px;
}

h6 {
  color: #eee;
  font-family: alt-font;
  font-size: 18px;
}

.image-block {
  width: 100%;
  height: 300px;
  display: block;
}

.mobile_art {
  width: 100%;
  height: 150px;
  display: none;
}

/*.submenu{
    width: calc( 100% - 10px ); padding: 5px; float: left;
    
    a{  color: #222; float: left; width: 120px; text-align: center; text-decoration: none;
       &:hover{ color : #222; float: left; width: 120px; text-align: center; border-bottom: 1px solid #fff; text-decoration: none;}
    }
}
*/
html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: zwippi-font, Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: #000 no-repeat center center;
  /*background-image: linear-gradient(135deg, #05432d 0%, #0d333f 23%, #477372 69%, #080c12 95%);  background-attachment: fixed;*/
  color: #eee;
  height: 100%;
  font-family: zwippi-font;
  font-size: 15px;
}

a {
  cursor: pointer;
}

.error-body {
  margin: 0;
  padding: 0;
  background: #B00;
  color: #222;
  height: 100%;
}

.error404 {
  height: 400px;
  background: #FFF;
  color: #222;
  margin: 100px auto;
}

.floatBreaker {
  height: 1px;
  overflow: hidden;
  margin-top: -1px;
  clear: both;
  float: none;
}

.red-dot {
  background-color: #b00;
  display: block;
  border-radius: 100%;
  width: 7px;
  height: 7px;
  position: relative;
  right: -20px;
  top: 8px;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.content .art {
  width: 150px;
  height: 500px;
  margin-top: 5px;
  background-color: #3c4c5c;
}

.content .text {
  width: calc( 100% - 170px);
  padding: 10px;
  margin-bottom: 300px;
}

.content .layoutBy2 {
  width: calc( 50% - 20px);
  padding: 10px;
  margin-bottom: 90px;
}

.content .layoutBy2 .user-image {
  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 100%;
  background: #333 url(../img/default-user.jpg) no-repeat center center;
  background-size: cover;
}

.content .layoutBy2Art {
  width: calc( 50% - 20px);
  padding: 10px;
  margin-bottom: 90px;
}

.content .layoutBy2_bottom {
  width: calc( 50% - 20px);
  padding: 10px;
  margin-bottom: 90px;
}

.content .layoutBy3 {
  width: calc( 33% - 20px);
  padding: 10px;
  margin-bottom: 90px;
}

.page-cover {
  position: relative;
  top: 0;
  left: 0;
  height: 300px;
  width: 100%;
  text-align: center;
}

.page-cover h1 {
  position: absolute;
  bottom: 0;
  text-align: center;
  display: block;
  width: 100%;
}

.gallery {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-width: 33%;
          column-width: 33%;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(black), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
}

.header .header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin: 0 auto;
  text-decoration: none;
  max-width: 1400px;
}

.header .header-wrapper .brand-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  width: 50%;
  max-width: 50%;
  margin: 0px;
}

.header .header-wrapper .brand-box .brand {
  width: 109px;
  height: 160px;
  background: url(../img/logo.png) no-repeat center top;
  background-size: 100%;
  margin: 0px;
  margin-top: 10px;
}

.header .header-wrapper .menu {
  width: calc( 50% - 20px);
  text-align: right;
  margin: 10px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
}

.header .header-wrapper .menu .mobile-menu-btn {
  display: none !important;
  width: 100px;
  height: 50px;
  line-height: 50px;
  margin-top: 22px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  color: #3c4c5c;
  text-transform: capitalize;
  text-decoration: none;
  -webkit-transition: all 0.08s ease;
  transition: all 0.08s ease;
}

.header .header-wrapper .menu .mobile-menu-btn:hover {
  color: #3c4c5c;
  border-top: 5px solid #d4a300;
}

.header .header-wrapper .menu .mobile-menu-btn:visited {
  text-decoration: none;
}

.header .header-wrapper .menu a {
  display: block;
  width: 100px;
  height: 50px;
  line-height: 50px;
  margin-top: 22px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
  -webkit-transition: all 0.08s ease;
  transition: all 0.08s ease;
  text-shadow: 5px 5px rgba(0, 0, 0, 0.3);
}

.header .header-wrapper .menu a:hover {
  color: #fff;
  border-top: 5px solid #d4a300;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.header .header-wrapper .menu a:visited {
  text-decoration: none;
}

.header .header-wrapper .menu .private-menu {
  margin-top: 22px;
  padding: 0px;
  padding-top: 10px;
  text-decoration: none;
  font-size: 30px;
  color: #666;
}

.header .header-wrapper .menu .private-menu:hover {
  color: #222;
}

.header .header-wrapper .dropbtn {
  text-decoration: none;
  color: #FFF !important;
  background: #333;
  padding: 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  min-width: 160px;
}

.header .header-wrapper .dropdown:hover .dropbtn {
  background: #3c4c5c !important;
}

.header .header-wrapper .dropdown {
  position: relative;
  display: inline-block;
}

.header .header-wrapper .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9 !important;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 30;
}

.header .header-wrapper .dropdown-content a {
  text-align: left !important;
  color: black;
  padding: 12px 16px !important;
  text-decoration: none;
  display: block !important;
  margin: 0 !important;
}

.header .header-wrapper .dropdown-content a:hover {
  background-color: #f1f1f1 !important;
}

.header .header-wrapper .dropdown:hover .dropdown-content {
  display: block;
}

.header .header-wrapper .video-box {
  width: 100%;
  height: 450px;
  background-color: #000;
}

.header .header-wrapper .home-box2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.header .header-wrapper .home-box2 .box1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  width: 50%;
  max-width: 50%;
  margin: 0px;
}

.header .header-wrapper .home-box2 .box1 a {
  color: #3c4c5c;
}

.sideMenu {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: -2px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  border: #900 2px solid;
  -webkit-transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
  transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
  transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in;
  transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}

.sideMenu-show {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
  transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
  transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in;
  transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in, -webkit-transform 0.12s ease-in;
}

.sideMenu-content {
  position: fixed;
  height: 100%;
  top: 0;
  right: -230px;
  background-color: #fff;
  width: 230px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
  transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
  transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease;
  transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease, -webkit-transform 0.15s;
}

.sideMenu-content-show {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0px;
  background-color: white;
  width: 230px;
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
  transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
  transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
  transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease, -webkit-transform 0.25s;
}

.sideMenuBrand {
  float: left;
  width: 100px;
  line-height: 1.5rem;
  text-align: left;
  margin: 10px;
  text-transform: uppercase;
  content: 'IBIN';
  color: #333;
  font-family: zwippi-font;
}

.sideMenu-close-button {
  float: right;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: lightgray;
  margin: 10px;
  margin-right: 30px;
}

.sideMenu-close-button:hover {
  background-color: #3c4c5c;
  color: #FFF;
}

.sideMenu-close-button-general {
  float: right;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: #FFF;
  margin: 3px;
  color: #A5AFBA;
}

.sideMenu-close-button-general:hover {
  background-color: #808E9D;
}

#sideMenuControlheader {
  margin-top: 42px;
  text-align: center;
  font-size: 24px;
  font-family: zwippi-font;
  text-align: center;
  border-top: 1px rgba(165, 175, 186, 0.5) solid;
}

#sideMenuControlheader lu {
  clear: both;
  text-indent: none;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}

#sideMenuControlheader li {
  clear: both;
  text-indent: none;
  counter-increment: section;
  list-style-type: none;
  border-bottom: 1px solid rgba(165, 175, 186, 0.5);
  -webkit-transition: background .45s ease;
  transition: background .45s ease;
}

#sideMenuControlheader li:hover {
  text-indent: none;
  list-style-type: none;
  border-bottom: 1px solid rgba(165, 175, 186, 0.5);
  background: #3c4c5c;
  color: #FFF;
}

#sideMenuControlheader li a::before {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  margin-left: 6px;
  content: '';
  font-size: 9px;
  color: #a5afba;
  line-height: 30px;
  display: inline-block;
}

#sideMenuControlheader li a {
  text-decoration: none;
  color: #221f1f;
  display: block;
  width: 100%;
  -webkit-transition: color .5s ease;
  transition: color .5s ease;
}

#sideMenuControlheader li a:hover {
  text-decoration: none;
  color: #fff;
  display: block;
  width: 100%;
}

.push {
  width: 100%;
  height: 100px;
}

.footer {
  margin-top: -80px;
  left: 0;
  width: 100%;
  background: #ffffff;
}

.footer .footer-wrapper {
  z-index: 11;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: auto;
  margin: 0px auto;
  margin-bottom: 10px;
  max-width: 1400px;
  width: calc( 100% - 20px);
  padding: 10px;
}

.footer .footer-wrapper .logo {
  width: 80px;
  height: 105px;
  background: #3c4c5c url(../img/flogo.jpg) no-repeat center top;
  background-size: 100%;
  margin: 0px;
}

.footer .footer-wrapper form {
  border: 1px solid #d4a300;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}

.footer .footer-wrapper form input[type='email'] {
  border: 0;
  display: inline-block;
  padding: 10px;
  margin: 0;
  width: calc( 75% - 20px);
}

.footer .footer-wrapper form button {
  border: 0;
  background-color: #006dd4;
  text-align: center;
  color: #3f3d3d;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 25%;
}

.footer .footer-wrapper .part_1 {
  width: calc( 33% - 20px);
  display: block;
  color: #242020;
  padding: 10px;
}

.footer .footer-wrapper .part_1 h3 {
  color: #006dd4;
}

.footer .footer-wrapper .part_1 a {
  border: 0;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  font-size: 24px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #242020;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-transition: all 0.08s ease;
  transition: all 0.08s ease;
}

.footer .footer-wrapper .part_1 a:hover {
  background-color: #006dd4;
  font-size: 30px;
}

.footer .footer-wrapper .part_2 {
  width: calc( 33% - 22px);
  border-left: 1px solid #383838;
  border-right: 1px solid #383838;
  display: block;
  color: #272525;
  padding: 10px;
  height: 200px;
}

.footer .footer-wrapper .part_2 h3 {
  color: #006dd4;
}

.footer .footer-wrapper .part_2 .footermenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}

.footer .footer-wrapper .part_2 .footermenu a {
  display: block;
  width: 50%;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  text-align: left;
  text-decoration: none;
  font-size: 13px;
  color: #111111;
  text-transform: capitalize;
  text-decoration: none;
  -webkit-transition: all 0.08s ease;
  transition: all 0.08s ease;
}

.footer .footer-wrapper .part_2 .footermenu a:hover {
  color: #004ad4;
  border-top: 1px solid #004ad4;
}

.footer .footer-wrapper .part_2 .footermenu a:visited {
  text-decoration: none;
}

.popup {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: visibility 0s ease 0.07s, opacity 0.03s 0s, -webkit-transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, -webkit-transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s, -webkit-transform 0.05s;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  padding: 0px;
  min-width: 220px;
  max-width: 380px;
  width: calc( 100% - 20px);
  border-radius: 1rem;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  background-color: lightgray;
  margin: 8px;
}

.close-button:hover {
  background-color: darkgray;
}

.show-popup {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: visibility 0s linear 0s, opacity 0.07s 0s, -webkit-transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, -webkit-transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s, -webkit-transform 0.1s;
}

.admin-popup {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: visibility 0s ease 0.07s, opacity 0.03s 0s, -webkit-transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, -webkit-transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s;
  transition: visibility 0s ease 0.07s, opacity 0.03s 0s, transform 0.05s, -webkit-transform 0.05s;
}

.show-admin-popup {
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: visibility 0s linear 0s, opacity 0.07s 0s, -webkit-transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, -webkit-transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s;
  transition: visibility 0s linear 0s, opacity 0.07s 0s, transform 0.1s, -webkit-transform 0.1s;
}

.admin-popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  padding: 0;
  width: 24rem;
  border-radius: 0.5rem;
}

.admin-close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: #C00;
}

.admin-close-button:hover {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: #900;
  color: #FFF;
}

.public-form {
  margin: 10px;
  padding: 10px;
  max-width: 720px;
  margin: 0 auto;
  border: 1px solid #eee;
  position: relative;
}

.public-form .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

.public-form .form-row label {
  width: 90px;
  display: block;
  margin: 10px;
  padding: 10px;
  text-align: left;
}

.public-form .form-row input[type='text'], .public-form .form-row input[type='email'], .public-form .form-row input[type='password'], .public-form .form-row textarea {
  display: block;
  width: calc( 100% - 174px);
  margin: 10px;
  padding: 10px;
}

.public-form .form-row .partial {
  width: calc(50% - 20px);
  padding: 10px;
}

.public-form button {
  background-color: #888;
  color: #fff;
  display: inline-block;
  margin: 10px;
  margin-right: 0px;
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.public-form button:hover {
  background-color: #3c4c5c;
  color: #fff;
  border: 0;
}

.zwippi-basic-form {
  margin: 10px auto;
  color: #000;
  width: calc( 100% - 20px);
  max-width: 640px;
  padding: 10px;
  border-bottom: 1px solid #CCC;
}

.zwippi-basic-form label {
  display: block;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  position: relative;
}

.zwippi-basic-form label span {
  display: none;
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  position: absolute;
  top: -5px;
  right: 0;
  height: 28px;
  background: #090;
  color: #FFF;
  font-size: 10px;
  line-height: 28px;
  border-radius: 32px;
}

.zwippi-basic-form input[type=text], .zwippi-basic-form input[type=password], .zwippi-basic-form input[type=file] {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px #222 solid;
  background-color: #fff;
  color: #222;
}

.zwippi-basic-form input[type=text]::-webkit-input-placeholder, .zwippi-basic-form input[type=password]::-webkit-input-placeholder, .zwippi-basic-form input[type=file]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.zwippi-basic-form input[type=text]:-ms-input-placeholder, .zwippi-basic-form input[type=password]:-ms-input-placeholder, .zwippi-basic-form input[type=file]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.zwippi-basic-form input[type=text]::-ms-input-placeholder, .zwippi-basic-form input[type=password]::-ms-input-placeholder, .zwippi-basic-form input[type=file]::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.zwippi-basic-form input[type=text]::placeholder, .zwippi-basic-form input[type=password]::placeholder, .zwippi-basic-form input[type=file]::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.zwippi-basic-form textarea {
  font-family: zwippi-font;
  font-size: 16px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px #222 solid;
}

.zwippi-basic-form input[type=file] {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(255, 255, 255, 0.9) solid;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
  display: none;
}

.zwippi-basic-form select {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px #222 solid;
  background-color: #fff;
  border: 1px #222 solid;
}

.zwippi-basic-form select option {
  display: block;
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px #222  solid;
  background-color: #fff;
  color: #222;
}

.zwippi-basic-form .redeemBlock {
  border: 1px #099 dotted;
  padding: 5px;
  margin-top: 5px;
}

.zwippi-basic-form .redeemCatagory {
  display: none;
}

.zwippi-basic-form button {
  background: #B00;
  font-family: zwippi-light-font;
  font-size: 18px;
  color: #FFF;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 0px);
  border: 0;
  cursor: pointer;
  float: right;
}

.zwippi-basic-form button:hover {
  background: #900;
  font-family: zwippi-light-font;
  font-size: 18px;
  color: #FFF;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 0px);
  border: 0;
  cursor: pointer;
  float: right;
}

.zwippi-basic-form .redeemtotal {
  background: #EEE;
  font-family: zwippi-font;
  font-size: 18px;
  color: #222;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 20px);
  border: 0;
  cursor: pointer;
  float: right;
  text-align: left;
  line-height: 30px;
}

.zwippi-basic-form .redeemtotal .part_1 {
  width: 120px;
  float: left;
  text-align: right;
  line-height: 30px;
}

.zwippi-basic-form .redeemtotal .part_2 {
  width: calc( 100% - 160px);
  margin-left: 10px;
  float: left;
  text-align: left;
  line-height: 30px;
}

.zwippi-basic-form .zwippiFormFooter {
  width: 100%;
  float: left;
  margin-top: 30px;
  font-size: 11px;
}

.zwippi-basic-form .zwippiFormFooter a {
  display: block;
  padding: 8px;
  float: left;
  width: calc( 50% - 34px);
  margin: 8px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  overflow: hidden;
}

.zwippi-basic-form .zwippiFormFooter a:hover {
  font-weight: bold;
  display: block;
  padding: 8px;
  float: left;
  width: calc( 50% - 34px);
  margin: 8px;
  text-align: center;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  overflow: hidden;
}

.zwippi-form {
  margin: 10px auto;
  color: #000;
  width: calc( 100% - 20px);
  max-width: 640px;
  padding: 10px;
  border-bottom: 1px solid #CCC;
  position: relative;
}

.zwippi-form .imageFile {
  display: block;
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 100%;
  background-color: #333;
}

.zwippi-form label {
  display: block;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  position: relative;
}

.zwippi-form label span {
  display: none;
  text-align: center;
  text-transform: uppercase;
  width: 80px;
  position: absolute;
  top: -5px;
  right: 0;
  height: 28px;
  background: #090;
  color: #333;
  font-size: 10px;
  line-height: 28px;
  border-radius: 32px;
}

.zwippi-form input[type=text], .zwippi-form [type=number], .zwippi-form input[type=password], .zwippi-form input[type=email], .zwippi-form input[type=file] {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(0, 0, 0, 0.7) solid;
  background-color: rgba(255, 255, 255, 0.2);
  color: #222;
}

.zwippi-form input[type=text]::-webkit-input-placeholder, .zwippi-form [type=number]::-webkit-input-placeholder, .zwippi-form input[type=password]::-webkit-input-placeholder, .zwippi-form input[type=email]::-webkit-input-placeholder, .zwippi-form input[type=file]::-webkit-input-placeholder {
  color: #999;
}

.zwippi-form input[type=text]:-ms-input-placeholder, .zwippi-form [type=number]:-ms-input-placeholder, .zwippi-form input[type=password]:-ms-input-placeholder, .zwippi-form input[type=email]:-ms-input-placeholder, .zwippi-form input[type=file]:-ms-input-placeholder {
  color: #999;
}

.zwippi-form input[type=text]::-ms-input-placeholder, .zwippi-form [type=number]::-ms-input-placeholder, .zwippi-form input[type=password]::-ms-input-placeholder, .zwippi-form input[type=email]::-ms-input-placeholder, .zwippi-form input[type=file]::-ms-input-placeholder {
  color: #999;
}

.zwippi-form input[type=text]::placeholder, .zwippi-form [type=number]::placeholder, .zwippi-form input[type=password]::placeholder, .zwippi-form input[type=email]::placeholder, .zwippi-form input[type=file]::placeholder {
  color: #999;
}

.zwippi-form textarea {
  font-family: zwippi-font;
  font-size: 16px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(255, 255, 255, 0.9) solid;
}

.zwippi-form input[type=file] {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(255, 255, 255, 0.9) solid;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
  display: none;
}

.zwippi-form select {
  font-family: zwippi-font;
  font-size: 22px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(255, 255, 255, 0.9) solid;
  background-color: rgba(0, 0, 0, 0.2);
  color: #222;
}

.zwippi-form select option {
  display: block;
  font-family: zwippi-font;
  font-size: 18px;
  padding: 10px;
  margin: 12px auto;
  width: calc( 100% - 22px);
  border: 1px rgba(150, 0, 0, 0.1) solid;
  background-color: #f8e3e3;
  color: #222;
}

.zwippi-form .redeemBlock {
  border: 1px #099 dotted;
  padding: 5px;
  margin-top: 5px;
}

.zwippi-form .redeemCatagory {
  display: none;
}

.zwippi-form button {
  background: #B00;
  font-family: zwippi-light-font;
  font-size: 18px;
  color: #FFF;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 0px);
  border: 0;
  cursor: pointer;
  float: right;
}

.zwippi-form button:hover {
  background: #900;
  font-family: zwippi-light-font;
  font-size: 18px;
  color: #FFF;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 0px);
  border: 0;
  cursor: pointer;
  float: right;
}

.zwippi-form .redeemtotal {
  background: #EEE;
  font-family: zwippi-font;
  font-size: 18px;
  color: #222;
  padding: 10px;
  margin-top: 10px;
  margin-right: 0px;
  width: calc( 50% - 20px);
  border: 0;
  cursor: pointer;
  float: right;
  text-align: left;
  line-height: 30px;
}

.zwippi-form .redeemtotal .part_1 {
  width: 120px;
  float: left;
  text-align: right;
  line-height: 30px;
}

.zwippi-form .redeemtotal .part_2 {
  width: calc( 100% - 160px);
  margin-left: 10px;
  float: left;
  text-align: left;
  line-height: 30px;
}

.zwippi-form .zwippiFormFooter {
  width: 100%;
  float: left;
  margin-top: 30px;
  font-size: 11px;
}

.zwippi-form .zwippiFormFooter a {
  display: block;
  padding: 8px;
  float: left;
  width: calc( 50% - 34px);
  margin: 8px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  overflow: hidden;
}

.zwippi-form .zwippiFormFooter a:hover {
  font-weight: bold;
  display: block;
  padding: 8px;
  float: left;
  width: calc( 50% - 34px);
  margin: 8px;
  text-align: center;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  overflow: hidden;
}

.transction-history-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  padding: 0px;
  margin-bottom: 10px;
  overflow: hidden;
  height: 34px;
  line-height: 34px;
  border-bottom: 1px solid #E3E3E3;
}

.transction-history-row .time-ago {
  font-size: 9px;
}

.transction-history-row .fee {
  color: #900;
}

.transction-history-row .gain-icon {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #333;
  border-radius: 100%;
  text-align: center;
}

.transction-history-row .transction-history-col-10 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 10% - 4px);
}

.transction-history-row .transction-history-col-15 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 15% - 4px);
}

.transction-history-row .transction-history-col-20 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 20% - 4px);
}

.transction-history-row .transction-history-col-25 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  width: calc( 25% - 4px);
}

.transction-history-row .transction-history-col-30 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 30% - 4px);
}

.transction-history-row .transction-history-col-40 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 40% - 4px);
}

.transction-history-row .transction-history-col-50 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 50% - 4px);
}

.transction-history-row .transction-history-col-60 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 60% - 4px);
}

.transction-history-row .transction-history-col-70 {
  padding: 2px;
  overflow: hidden;
  height: 34px;
  margin-top: -2px;
  width: calc( 70% - 4px);
}

.preloader-on {
  position: absolute;
  z-index: 100;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #FFF;
}

.preloader-off {
  position: absolute;
  z-index: 100;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #FFF;
}

.preloader-fullscreen {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #FFF;
  left: -100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: shadowRolling 2s linear infinite;
          animation: shadowRolling 2s linear infinite;
}

@-webkit-keyframes shadowRolling {
  0% {
    -webkit-box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    -webkit-box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    -webkit-box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    -webkit-box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    -webkit-box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
            box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }
  75% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }
  87% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

@keyframes shadowRolling {
  0% {
    -webkit-box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  12% {
    -webkit-box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  25% {
    -webkit-box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }
  36% {
    -webkit-box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
            box-shadow: 120px 0 white, 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0);
  }
  50% {
    -webkit-box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
            box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }
  62% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white, 110px 0 white;
  }
  75% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white;
  }
  87% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }
  100% {
    -webkit-box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
            box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

.preloader-spinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

.preloader-spinner::after {
  content: '';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #FF3D00 #FF3D00;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-animation: rotationBack 0.5s linear infinite;
          animation: rotationBack 0.5s linear infinite;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes rotationBack {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.responsive-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}

.responsive-wrapper .prevBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 22px;
  position: absolute;
  left: 0;
  top: 50%;
  background-color: transparent;
  border: 0;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: 40px;
}

.responsive-wrapper .nextBtn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: transparent;
  border: 0;
  color: #fff;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-right: 40px;
}

.responsive {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  display: block;
  background-color: #ccc;
  margin-top: 0px;
}

.responsive .slider {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
}

.responsive .slider img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.responsive .slider-content {
  bottom: 0;
  margin: 0;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  width: 50%;
  height: 100vh;
  text-align: right;
}

.responsive .slider-content .slide-text {
  display: block;
  width: 400px;
  position: absolute;
  right: 10px;
  top: 50%;
  line-height: 50px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.responsive .slider-content .slide-text .bt1 {
  font-size: 45px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
}

.responsive .slider-content .slide-text .bt2 {
  font-size: 45px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
}

.responsive .slider-content .slide-text .summary {
  font-size: 14px;
  color: #fff;
}

.imageFile {
  cursor: pointer;
  position: absolute;
  right: -15px;
  top: -15px;
  display: block;
  font-size: 26px;
  border-radius: 100%;
  background: #090;
  color: #FFF;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.image-style {
  float: left;
  text-align: center;
  width: 60px;
  height: 50px;
  margin-top: 11px;
  font-size: 18px;
  line-height: 50px;
  background: #EEE;
}

/*
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  */
/* desktop UI*/
@media only screen and (min-width: 1058px) {
  /* header*/
  /* PLAY UI*/
  .play-history {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    padding: 0px;
    border-radius: 30px;
    margin-bottom: 10px;
    overflow: hidden;
    height: 34px;
    background: #ccc;
    /*box-shadow: inset 0 0 10px rgba(0,0,0,.3);*/
  }
  .play-history .filter {
    margin-top: -2px;
    width: 120px;
    padding: 2px;
    text-align: center;
    border-right: 1px solid #87cf28;
    height: 34px;
    line-height: 34px;
    color: #222;
    font-family: alt-bold-font;
  }
  .play-history .filter .icon-indi {
    display: inline-block;
    height: 15px;
    margin: 5px;
    width: 15px;
    background-color: #0C3;
    border-radius: 100%;
  }
  .play-history .point-history {
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
    background: #EEE;
    padding: 2px;
    overflow: hidden;
    height: 34px;
    margin-top: -2px;
    width: calc( 100% - 272px);
  }
  .play-history .point-history .play-point-init {
    display: none;
    opacity: 0;
    overflow: hidden;
    width: 5px;
    height: 5px;
    margin: 20px;
    float: left;
    background: #3A9;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    display: block;
    font-size: 9px;
    font-family: alt-bold-font;
    border-radius: 100%;
    visibility: visible;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
  }
  .play-history .point-history .play-point-win {
    display: block;
    opacity: 1;
    width: 30px;
    height: 30px;
    margin: 2px;
    float: left;
    background: #3A9;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    display: block;
    font-size: 9px;
    font-family: alt-bold-font;
    border-radius: 100%;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play-history .point-history .play-point-win:hover {
    width: 30px;
    height: 30px;
    margin: 2px;
    float: left;
    background: #0C3;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    display: block;
    font-size: 11px;
    font-family: alt-bold-font;
    border-radius: 100%;
  }
  .play-history .point-history .play-point-lose {
    display: block;
    opacity: 1;
    width: 30px;
    height: 30px;
    margin: 2px;
    float: left;
    background: #B00;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    display: block;
    font-size: 9px;
    font-family: alt-bold-font;
    border-radius: 100%;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play-history .point-history .play-point-lose:hover {
    width: 30px;
    height: 30px;
    margin: 2px;
    float: left;
    background: #E00;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    display: block;
    font-size: 11px;
    font-family: alt-bold-font;
    border-radius: 100%;
  }
  .play-history .active-users {
    margin-top: -2px;
    padding: 2px;
    width: 120px;
    text-align: center;
    border-left: 1px solid #87cf28;
    height: 34px;
    line-height: 34px;
    color: #222;
  }
  .point-ui .opponent {
    background-color: #090;
    color: #fff;
    margin: 0;
    text-align: center;
    padding: 10px;
  }
  .point-ui .opponent .image-header {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 5px auto;
  }
  .point-ui .opponent .name {
    font-size: 18px;
    font-family: alt-bold-font;
  }
  .point-ui .opponent .province {
    font-size: 13px;
  }
  .point-ui .opponent .topline {
    font-size: 12px;
  }
  .point-ui .details {
    text-align: center;
    color: #222;
    width: calc( 100% - 20px);
    padding: 10px;
  }
  .point-ui .details .textarea {
    width: calc( 100% - 20px);
    padding: 10px;
  }
  .point-ui .details .sendbtn {
    background-color: #999;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 50%;
    padding: 10px;
    margin: 10px auto;
    border: 0;
    display: block;
  }
  .point-ui .details .sendbtn:hover {
    background-color: #B00;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 50%;
    padding: 10px;
    margin: 10px auto;
    border: 0;
    display: block;
  }
  .play {
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    overflow: hidden;
    border-radius: 7px;
    padding: 1px;
    height: 0;
    margin-top: 12px;
    position: relative;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    color: #fff;
    display: block;
  }
  .play .reason {
    display: none;
    border-radius: 30px;
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #87cf28;
    line-height: 30px;
    height: 30px;
    width: 50px;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play .reason-show-win {
    display: block;
    border-radius: 0px;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    background: #87cf28;
    width: 100%;
    line-height: 80px;
    height: 100%;
    color: #FFF;
    text-align: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    font-size: 24px;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play .reason-show-lose {
    display: block;
    border-radius: 0px;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    background: #B00;
    width: 100%;
    line-height: 80px;
    height: 100%;
    color: #FFF;
    text-align: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    font-size: 24px;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play .reason-show-cancel {
    display: block;
    border-radius: 0px;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    background: #222;
    width: 100%;
    line-height: 80px;
    height: 100%;
    color: #FFF;
    text-align: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    font-size: 24px;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play .reason-show-message {
    display: block;
    border-radius: 0px;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    background: #222;
    width: 100%;
    line-height: 80px;
    height: 100%;
    color: #FFF;
    text-align: center;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    font-size: 24px;
    -webkit-transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
    transition: transform 0.25s ease-in, opacity 0.5s ease 0.5s, font-size 0.5s ease 0.5s, background-color 0.5s ease 0.5s, -webkit-transform 0.25s ease-in;
  }
  .play .amountImg {
    margin-right: 4px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 100%;
    background-color: #ccc;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin-top: 4px;
    margin-left: 4px;
    border: 2px solid #999;
  }
  .play .playBtn {
    width: 100px;
    height: 40px;
    margin-top: 20px;
    margin-right: 4px;
    border-radius: 30px;
    background: #999;
    float: right;
    position: relative;
    overflow: hidden;
  }
  .play .fillTimer {
    width: 0px;
    height: 41px;
    background-color: #ffee00;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
  .play .tails {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #EEE url(../img/svg/tails.svg) no-repeat center center;
    background-size: cover;
    float: right;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .tails:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #B00 url(../img/svg/tails.svg) no-repeat center center;
    background-size: cover;
    float: right;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .heads {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #EEE url(../img/svg/heads.svg) no-repeat center center;
    background-size: cover;
    text-align: center;
    /* url(/img/headsBtn.svg) no-repeat center top; background-size: cover;*/
    float: left;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .heads:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #B00 url(../img/svg/heads.svg) no-repeat center center;
    background-size: cover;
    float: left;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .cancel {
    width: 90px;
    height: 30px;
    border-radius: 20px;
    background: #EEE;
    margin: 5px auto;
    display: block;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }
  .play .cancel:hover {
    width: 90px;
    height: 30px;
    border-radius: 20px;
    background: #B00;
    color: #FFF;
    margin: 5px auto;
    display: block;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }
  .play .gazada-tails {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #EEE url(../img/svg/tails.svg) no-repeat center center;
    background-size: cover;
    float: right;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .gazada-tails:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #B00 url(../img/svg/tails.svg) no-repeat center center;
    background-size: cover;
    float: right;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .gazada-heads {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #EEE url(../img/svg/heads.svg) no-repeat center center;
    background-size: cover;
    text-align: center;
    /* url(/img/headsBtn.svg) no-repeat center top; background-size: cover;*/
    float: left;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .gazada-heads:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #B00 url(../img/svg/heads.svg) no-repeat center center;
    background-size: cover;
    float: left;
    margin: 5px;
    text-indent: 50px;
    overflow: hidden;
  }
  .play .gazada-cancel {
    width: 90px;
    height: 30px;
    border-radius: 20px;
    background: #EEE;
    margin: 5px auto;
    display: block;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }
  .play .gazada-cancel:hover {
    width: 90px;
    height: 30px;
    border-radius: 20px;
    background: #B00;
    color: #FFF;
    margin: 5px auto;
    display: block;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }
  .play .opponent {
    width: calc( 100% - $play-icon-diameter - 104px);
    margin-top: 12px;
    padding-left: 8px;
    float: left;
    line-height: 15px;
    color: #000;
    font-family: alt-font;
  }
  .play .opponent .name {
    font-size: 14px;
    display: block;
  }
  .play .opponent .name .user-icon {
    margin-right: 2px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin-top: 0px;
    margin-left: 2px;
    border: 1px #ccc solid;
    display: none;
  }
  .play .opponent .name .province {
    font-size: 12px;
    font-family: alt-font;
    color: #333;
  }
  .play .opponent .amount {
    font-size: 20px;
    color: #000;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: alt-bold-font;
  }
  .play .opponent .amount .value-icon {
    margin-right: 2px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin-top: -5px;
  }
  .play .opponent .date {
    font-size: 9px;
    display: block;
    color: #000;
    font-family: alt-bold-font;
  }
  .play .opponent .date .date-icon {
    margin-right: 4px;
    width: 8px;
    height: 8px;
    line-height: 8px;
    border-radius: 100%;
    background-color: #43d300;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin-top: 3px;
    margin-left: 0px;
  }
  .play .opponent .message {
    font-size: 11px;
  }
  .play .play-underline {
    width: 100%;
    margin: 0 auto;
    height: 0px;
    display: none;
    background-color: #B00;
    border-radius: 2px;
  }
  .play::after {
    height: 1px;
    overflow: hidden;
    margin-top: -1px;
    clear: both;
    float: none;
    content: '';
  }
  /**/
  .show {
    opacity: 1;
    width: calc( 100% - 12px);
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
    height: 82px;
  }
  .hide {
    opacity: 0;
    width: calc( 100% - 12px)/2;
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    visibility: visible;
    height: 0px;
  }
  /*      
    .play{
            box-shadow: 0 0 8px rgba(0,0,0,.2);
            background-color:#FFF;
            overflow: hidden;
            border-radius: 7px; width : calc( 100% - 12px ); 
            padding: 4px;
            height: $play-height;
            margin-top: 12px;
            position: relative;
            opacity: 1;
            visibility: visible;
            transform: scale(1.0);
            transition: visibility .5s linear .5s, opacity 0.5s 0s, transform   s;
           // transition:  .3s;/* visibility .5s linear 0s, opacity 1s 1s, transform 1s;
            color: $item-font-color-1;


    .reason{ display: none; border-radius: 30px;  position: absolute; z-index: 3; left: 50%; top: 50%;  transform: translate(-50%, -50%);
         background: rgb(135, 207, 40); line-height: 30px; height: 30px; width: 50px; color:#FFF; text-align:center; font-size: 12px; 
            transform: scale(1.5);
            opacity: 0;
            transition: $transition-action; }
    
            .reason-show-win{ display: block; border-radius: 0px;   position: absolute; z-index: 3; left: 0; top: 0;  transform: translate(0, 0);
                 background: rgb(135, 207, 40);  width: 100%;  line-height: $play-height; height:  100%; color:#FFF; text-align:center;
                transform: scale(1.0);
                opacity: 1;
                font-size: 24px;
                transition: $transition-action;  }
            
            .reason-show-lose{ display: block; border-radius: 0px;   position: absolute; z-index: 3; left: 0; top: 0;  transform: translate(0, 0);
                background: #B00;  width: 100%;  line-height: $play-height; height: 100%; color:#FFF; text-align:center;
                    transform: scale(1.0);
                    opacity: 1;
                    font-size: 24px;
                    transition: $transition-action;  }
            
            .reason-show-cancel{ display: block; border-radius: 0px;   position: absolute; z-index: 3; left: 0; top: 0;  transform: translate(0, 0);
                background: #222;  width: 100%;  line-height: $play-height; height: 100%; color:#FFF; text-align:center;
                        transform: scale(1.0);
                        opacity: 1;
                        font-size: 24px;
                        transition: $transition-action;  }
            .reason-show-message{ display: block; border-radius: 0px;   position: absolute; z-index: 3; left: 0; top: 0;  transform: translate(0, 0); background: #222;  width: 100%;  line-height: $play-height; height: 100%; color:#FFF; text-align:center;
                            transform: scale(1.0);
                            opacity: 1;
                            font-size: 24px;
                            transition: $transition-action;  }
                                                           
    .amountImg{
       
        @include makeRoundIcon( $play-icon-diameter , 4px , #ccc , left );
            
           margin-top: 4px;
           margin-left: 4px; 
           border: 2px solid $theme-color;}
    
    .playBtn{ width: 100px; height: 40px; margin-top: ( $play-height - 40px ) / 2 ; margin-right: 4px; border-radius: 30px; background: $theme-color; float:right; position: relative; overflow: hidden;}
    .fillTimer{ width: 0px; height: 41px; background-color:rgb(255, 238, 0); position: absolute; bottom: -1px; left: 0;}
    
    .tails{ width: 30px; height: 30px; border-radius: 100%; background:#EEE url(../img/svg/tails.svg) no-repeat center center; background-size: cover; float:right; margin: 5px; text-indent: 50px; overflow:hidden;}
    .tails:hover{width: 30px; height: 30px; border-radius: 100%; background:#B00 url(../img/svg/tails.svg) no-repeat center center; background-size: cover;; float:right; margin: 5px; text-indent: 50px; overflow:hidden;}
    
    .heads{ width: 30px; height: 30px; border-radius: 100%; background:#EEE url(../img/svg/heads.svg) no-repeat center center; background-size: cover; text-align: center;  float: left; margin: 5px; text-indent: 50px; overflow:hidden;}
    .heads:hover{width: 30px; height: 30px; border-radius: 100%; background:#B00 url(../img/svg/heads.svg) no-repeat center center; background-size: cover;; float:left; margin: 5px; text-indent: 50px; overflow:hidden;}
    
    .cancel{ width: 90px; height: 30px; border-radius: 20px; background:#EEE; margin: 5px auto; display:block; line-height: 30px; text-align:center; text-transform:uppercase;}
    .cancel:hover{width: 90px; height: 30px; border-radius: 20px; background:#B00; color: #FFF; margin: 5px auto; display:block; line-height: 30px; text-align:center; text-transform:uppercase;}
    
    .gazada-tails{ width: 30px; height: 30px; border-radius: 100%; background:#EEE url(../img/svg/tails.svg) no-repeat center center; background-size: cover; float:right; margin: 5px; text-indent: 50px; overflow:hidden;}
    .gazada-tails:hover{width: 30px; height: 30px; border-radius: 100%; background:#B00 url(../img/svg/tails.svg) no-repeat center center; background-size: cover;; float:right; margin: 5px; text-indent: 50px; overflow:hidden;}
    
    .gazada-heads{ width: 30px; height: 30px; border-radius: 100%; background:#EEE url(../img/svg/heads.svg) no-repeat center center; background-size: cover; text-align: center;  float: left; margin: 5px; text-indent: 50px; overflow:hidden;}
    .gazada-heads:hover{width: 30px; height: 30px; border-radius: 100%; background:#B00 url(../img/svg/heads.svg) no-repeat center center; background-size: cover;; float:left; margin: 5px; text-indent: 50px; overflow:hidden;}
    
    .gazada-cancel{ width: 90px; height: 30px; border-radius: 20px; background:#EEE; margin: 5px auto; display:block; line-height: 30px; text-align:center; text-transform:uppercase;}
    .gazada-cancel:hover{width: 90px; height: 30px; border-radius: 20px; background:#B00; color: #FFF; margin: 5px auto; display:block; line-height: 30px; text-align:center; text-transform:uppercase;}
    
    .opponent{ overflow: hidden; width: calc( 100% - 204px ); margin-top: 12px; padding-left: 8px; float:left; line-height: 15px;  color: #000; font-family: $alt-font; 
        .name{  font-size:  14px;  display: block;
        
            .user-icon{  @include makeRoundIcon( 25px , 2px , #999 , left ); margin-top : 0px; margin-left : 2px; border: 1px #ccc solid; display: none; }
            .province{ font-size: 12px;  font-family: $alt-font; color: #333;}
        
        }
        
        .amount{ font-size:20px; color: #000; display: block; margin-top : 5px; margin-bottom: 5px; font-family: $bold-alt-font;
    
                .value-icon{  @include makeRoundIcon( 22px , 2px , #999 , left ); margin-top : -5px; }
        
            }   
    
            .date{font-size: 9px; display: block; color: #000; font-family: $bold-alt-font; 
                
                .date-icon{  @include makeRoundIcon( 8px , 4px , rgb(67, 211, 0) , left ); margin-top : 3px; margin-left : 0px; }
    
            }
        .message{font-size:  11px;}	
        
    }
    
    .play-underline{  width:  100% ; margin: 0 auto; height: 0px; display: none; background-color: #B00; border-radius: 2px; } 
    
    &::after{  height:1px;  overflow:hidden;  margin-top:-1px; clear:both; float:none; content: '';}
/* 
    }
}*/
  .topup {
    background-color: #fff;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    color: #fff;
    width: calc(100% - 32px);
    padding: 8px;
    float: left;
    border-bottom: 1px solid #CCC;
  }
  .topup .feedback {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #090;
    color: #FFF;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: calc( 20% - 30px);
    font-size: 30px;
    display: none;
  }
  .topup .status {
    width: 120px;
    float: left;
    text-align: center;
    background: #9C0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .topup .status span {
    display: block;
    color: #FFF;
  }
  .topup .status .rIcon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
    margin: 0 auto;
    display: block;
    border: 2px solid #FFF;
    border-radius: 100%;
  }
  .topup .status .dateSmall {
    font-size: 10px;
  }
  .topup .part_1 {
    width: calc( 100% - 270px);
    padding: 5px;
    float: left;
    font-size: 13px;
    line-height: 22px;
  }
  .topup .part_1 span {
    display: block;
    color: #222;
  }
  .topup .part_2 {
    width: 120px;
    float: right;
    padding-top: 60px;
  }
  .topup-done {
    background-color: #fff;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: calc(100% - 16px);
    padding: 8px;
    float: left;
    border-bottom: 1px solid #CCC;
  }
  .topup-done .status {
    width: 120px;
    float: left;
    text-align: center;
    background: #CCC;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .topup-done .status span {
    display: block;
    color: #FFF;
  }
  .topup-done .status .rIcon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
    margin: 0 auto;
    display: block;
    border: 2px solid #FFF;
    border-radius: 100%;
  }
  .topup-done .status .dateSmall {
    font-size: 10px;
  }
  .topup-done .part_1 {
    width: calc( 100% - 270px);
    padding: 5px;
    float: left;
    font-size: 13px;
    line-height: 22px;
  }
  .topup-done .part_1 span {
    display: block;
    color: #222;
  }
  .topup-done .part_2 {
    width: 120px;
    float: right;
    padding-top: 60px;
  }
  .redeem {
    background-color: #fff;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: calc(100% - 16px);
    padding: 8px;
    float: left;
    border-bottom: 1px solid #CCC;
  }
  .redeem .status {
    width: 120px;
    float: left;
    text-align: center;
    background: #9C0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .redeem .status span {
    display: block;
    color: #FFF;
  }
  .redeem .status .rIcon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
    margin: 0 auto;
    display: block;
    border: 2px solid #FFF;
    border-radius: 100%;
  }
  .redeem .status .dateSmall {
    font-size: 10px;
  }
  .redeem .part_1 {
    width: calc( 100% - 270px);
    padding: 5px;
    float: left;
    font-size: 13px;
    line-height: 22px;
  }
  .redeem .part_1 span {
    display: block;
    color: #222;
  }
  .redeem .part_2 {
    width: 120px;
    float: right;
    padding-top: 60px;
  }
  .redeem-processing {
    background-color: #fff;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: calc(100% - 16px);
    padding: 8px;
    float: left;
    border-bottom: 1px solid #CCC;
  }
  .redeem-processing .status {
    width: 120px;
    float: left;
    text-align: center;
    background: #09C;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .redeem-processing .status span {
    display: block;
    color: #FFF;
  }
  .redeem-processing .status .rIcon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
    margin: 0 auto;
    display: block;
    border: 2px solid #FFF;
    border-radius: 100%;
  }
  .redeem-processing .status .dateSmall {
    font-size: 10px;
  }
  .redeem-processing .part_1 {
    width: calc( 100% - 270px);
    padding: 5px;
    float: left;
    font-size: 13px;
    line-height: 22px;
  }
  .redeem-processing .part_1 span {
    display: block;
    color: #222;
  }
  .redeem-processing .part_2 {
    width: 120px;
    float: right;
    padding-top: 60px;
  }
  .redeem-done {
    background-color: #fff;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: calc(100% - 16px);
    padding: 8px;
    float: left;
    border-bottom: 1px solid #CCC;
  }
  .redeem-done .status {
    width: 120px;
    float: left;
    text-align: center;
    background: #999;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .redeem-done .status span {
    display: block;
    color: #FFF;
  }
  .redeem-done .status .rIcon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #FFF;
    margin: 0 auto;
    display: block;
    border: 2px solid #FFF;
    border-radius: 100%;
  }
  .redeem-done .status .dateSmall {
    font-size: 10px;
  }
  .redeem-done .status .ticket-label {
    font-family: alt-bold-font;
    color: #FFF;
  }
  .redeem-done .part_1 {
    width: calc( 100% - 270px);
    padding: 5px;
    float: left;
    font-size: 13px;
    line-height: 22px;
  }
  .redeem-done .part_1 span {
    display: block;
    color: #222;
  }
  .redeem-done .part_2 {
    width: 120px;
    float: right;
    padding-top: 60px;
  }
  .redeemCtr {
    float: right;
  }
  .redeemCtr a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: left;
    margin: 5px;
    border-radius: 100%;
    background: #9c6;
    font-size: 7px;
    text-transform: uppercase;
    color: #FFF;
    text-align: center;
    font-weight: lighter;
  }
  .redeemCtr a:hover {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: left;
    margin: 5px;
    border-radius: 100%;
    background: #B00;
    font-size: 7px;
    text-transform: uppercase;
    color: #FFF;
    font-weight: normal;
  }
  .redeemCtr span {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    float: left;
    margin: 5px;
    border-radius: 100%;
    background: #666;
    text-align: center;
    font-size: 7px;
    text-transform: uppercase;
    color: #CCC;
    font-weight: normal;
  }
  .ticketAmount {
    font-size: 32px;
    display: block;
    font-family: alt-bold-font;
  }
  .zwippi-project {
    float: left;
    width: calc( 100% - 20px);
    background: #EEE;
    border-bottom: #B00 5px solid;
    padding: 5px;
    margin: 5px;
    color: #fff;
  }
  .zwippi-project .heading {
    float: left;
    width: calc( 100% - 20px);
    background: #EEE;
    border-top: #B00 1px solid;
    padding: 5px;
    margin: 5px;
  }
  .zwippi-project .part_1 {
    float: left;
    width: calc( 50% - 20px);
    padding: 5px;
    margin: 5px;
  }
  .zwippi-project .part_2 {
    float: left;
    width: calc( 50% - 20px);
    padding: 5px;
    margin: 5px;
  }
  .zwippi-project .projectImg {
    width: 120px;
    height: 120px;
    background: #B00;
    border-radius: 100%;
    position: absolute;
    right: 5%;
    bottom: -60px;
    text-align: center;
    line-height: 120px;
    border: #FFF 5px solid;
  }
  .zwippi-project .projectCoverImg {
    border: #FFF 5px solid;
    width: calc( 100% - 10px);
    height: 220px;
    background: #B00;
    position: relative;
  }
  .zwippi-project .projectCoverImg label {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #9B6;
    border-radius: 100%;
    position: absolute;
    right: -15px;
    cursor: pointer;
    top: -15px;
    text-align: center;
    border: #FFF 5px solid;
    color: #fff;
  }
  .zwippi-project .projectCoverImg label:hover {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #3D0;
    border-radius: 100%;
    position: absolute;
    right: -15px;
    cursor: pointer;
    top: -15px;
    text-align: center;
    border: #FFF 5px solid;
    color: #fff;
  }
  /* CHAT UI*/
  /* 04.d Chat */
  .back-btn {
    font-size: 32px;
    float: left;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 10px;
  }
  .back-btn:hover {
    font-size: 32px;
    float: left;
    color: #fff;
    margin-top: 10px;
  }
  .chat-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: calc( 100% - 6px);
    max-width: calc( 100% - 6px);
    margin-top: 3px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(94, 12, 12, 0.1);
    position: fixed;
    z-index: 10;
  }
  .chat-header .clearBtn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 5px;
    margin-top: 10px;
    text-align: center;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.068);
    border-radius: 100%;
    font-size: 11px;
  }
  .chat-header .clearBtn:hover {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 5px;
    margin-top: 10px;
    text-align: center;
    color: #FFF;
    background-color: rgba(112, 12, 12, 0.123);
    border-radius: 100%;
    font-size: 14px;
  }
  .chat-header .userImage {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background: #dcf3f5;
    margin: 5px;
    position: relative;
  }
  .chat-header .userImage .clients {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #dcf3f5;
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .chat-header .userInfo {
    width: calc( 50% - 160px);
    padding-top: 10px;
    float: left;
    line-height: 15px;
    text-decoration: none;
    color: white;
    line-height: 15px;
  }
  .chat-header .userInfo .friend-name {
    font-size: 16px;
  }
  .chat-header .userInfo .friend-province {
    font-size: 11px;
  }
  .chat-header .userInfo #subject {
    display: none;
  }
  .chatBox {
    width: calc( 100% - 20px);
    height: calc( 100vh - 200px);
    padding: 9px;
    position: relative;
    bottom: 0;
  }
  .chatBox .chatBar {
    width: 100vw;
    position: fixed;
    bottom: 0;
    background-color: #222;
    left: 0;
    display: block;
  }
  .chatBox .chatBar .chatBarWrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .chatBox .chatBar .attachBtn {
    width: 15%;
    float: left;
  }
  .chatBox .chatBar .messageBox {
    width: calc(100% - 80px);
    float: left;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
  }
  .chatBox .chatBar .messageBtn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-top: 10px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    background: #396;
    float: left;
  }
  .chatBox .chatBar .messageBtn:hover {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin-top: 10px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    background: #6C9;
    float: left;
  }
  .chatBox .messageScreen {
    width: calc(100% - 20px);
    max-height: calc( 100vh - 400px);
    height: 300px;
    overflow-y: scroll;
    margin: 5px;
    margin-bottom: 90px;
    padding: 5px;
    background: #06022c;
    border-radius: 7px;
    background-image: -webkit-gradient(linear, left top, right bottom, from(#200211), to(rgba(1, 8, 5, 0.904)));
    background-image: linear-gradient(to bottom right, #200211, rgba(1, 8, 5, 0.904));
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-x: hidden;
  }
  .message_status {
    display: block;
    width: 20px;
    float: right;
  }
  .message_unread {
    color: #999;
  }
  .message_read {
    color: #0df87b;
  }
  .clients {
    float: left;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #333;
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .connectionList {
    width: 100%;
    max-width: 500px;
    background: #FFF;
    margin: 5px auto;
  }
  .connect-row {
    float: left;
    width: 100%;
    background: #FFF;
    border: 1px solid #eee;
    border-radius: 70px;
    margin-top: 5px;
  }
  .connect-row .userImage {
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    margin: 5px;
    background: #222;
  }
  .connect-row .userInfo {
    width: calc( 100% - 160px);
    height: 60px;
    border-radius: 100%;
    float: left;
    line-height: 25px;
    text-decoration: none;
    color: #333;
    line-height: 20px;
  }
  .connect-row .userInfo .connectName {
    color: #333;
    font-size: 20px;
    display: block;
    margin-top: 8px;
  }
  .connect-row .userInfo .connectSubject {
    color: #39C;
    font-size: 16px;
    font-style: italic;
  }
  .connect-row .userInfo .connectDate {
    color: #999;
    font-size: 12px;
  }
  .connect-row .user-new-message {
    float: right;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 100%;
    margin: 5px;
    background: #222;
    text-align: center;
    font-size: 11px;
  }
  .connect-row .user-new-message:hover {
    float: right;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 100%;
    margin: 5px;
    background: #B00;
    text-align: center;
    font-size: 11px;
  }
  #typeIndicator {
    display: none;
    padding: 3px;
    border-radius: 7px;
    background: #6C9;
    text-align: center;
    font-size: 10px;
    color: #FFF;
    text-align: center;
  }
  .messageScreen {
    width: calc(100% - 20px);
    min-height: calc( 100vh - 300px);
    margin: 5px;
    padding: 5px;
    background: #EEE;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .message_status {
    display: block;
    width: 20px;
    float: right;
  }
  .message_unread {
    color: #999;
  }
  .message_read {
    color: #0DA;
  }
  .sender {
    width: auto;
    min-width: 150px;
    max-width: calc( 100% - 20px);
    padding: 5px;
    margin: 5px;
    float: right;
    color: #ccc;
  }
  .sender .userimage {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #dcf3f5;
  }
  .sender .speechBubble {
    width: calc( 100% - 10px);
    padding: 5px;
    position: relative;
    background-color: #FFF;
    font-size: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  .sender .speechBubble .username {
    float: left;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 10px;
  }
  .sender .speechBubble .userdate {
    float: right;
    font-size: 10px;
  }
  .sender .speechBubble .delete {
    float: right;
    font-size: 10px;
    display: block;
    width: 50px;
  }
  .sender .speechBubble .messageArea {
    font-size: 12px;
    line-height: 18px;
    float: none;
    width: 100%;
    text-align: left;
  }
  .sender .speechBubble .reaction {
    font-size: 12px;
    line-height: 22px;
    float: none;
  }
  .sender .speechBubble .reaction a {
    display: block;
    float: right;
    width: 40px;
  }
  .sender .speechBubble:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-left-color: #FFF;
    border-right: 0;
    border-top: 0;
    margin-top: 0px;
    margin-right: -8px;
  }
  .reciever {
    width: auto;
    min-width: 150px;
    max-width: calc( 100% - 20px);
    padding: 5px;
    margin: 5px;
    float: left;
    color: #ccc;
  }
  .reciever .userimage {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #dcf3f5;
  }
  .reciever .speechBubble {
    float: left;
    width: calc( 100% - 10px);
    padding: 5px;
    position: relative;
    background-color: #b6cde7;
    font-size: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  .reciever .speechBubble .username {
    float: left;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 10px;
  }
  .reciever .speechBubble .userdate {
    float: right;
    font-size: 10px;
  }
  .reciever .speechBubble .delete {
    float: right;
    font-size: 10px;
    display: block;
    width: 50px;
  }
  .reciever .speechBubble .messageArea {
    font-size: 12px;
    line-height: 18px;
    float: none;
    width: 100%;
    text-align: left;
  }
  .reciever .speechBubble .reaction {
    font-size: 12px;
    line-height: 22px;
    float: none;
  }
  .reciever .speechBubble .reaction a {
    display: block;
    float: right;
    width: 40px;
  }
  .reciever .speechBubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #b6cde7;
    border-left: 0;
    border-top: 0;
    margin-top: -0px;
    margin-left: -8px;
  }
  .unread {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: calc( 1000% - 20px);
    max-width: calc( 100% - 20px);
    margin: 5px;
    padding: 5px;
    border-radius: 33px;
    background-color: #db5757;
    text-decoration: none;
  }
  .unread:hover {
    background-color: #b43939;
  }
  .unread .userImage {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #999;
  }
  .unread .user-deatails {
    width: calc( 100% - 130px);
    padding-left: 8px;
    padding-top: 8px;
    color: #fff;
  }
  .unread .message-count {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100%;
    background-color: #B00;
    color: #EEE;
  }
  .read {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: calc( 1000% - 20px);
    max-width: calc( 100% - 20px);
    margin: 5px;
    padding: 5px;
    border-radius: 33px;
    background-color: #eee;
    text-decoration: none;
  }
  .read:hover {
    background-color: #ccc;
  }
  .read .userImage {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #999;
  }
  .read .user-deatails {
    width: calc( 100% - 130px);
    padding-left: 8px;
    padding-top: 8px;
  }
  .read .message-count {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100%;
    background-color: #333;
    color: #EEE;
  }
  /* GAZADA UI*/
  .project {
    float: left;
    width: calc( 100% - 10px);
    padding: 5px;
    margin-bottom: 5px;
    line-height: 30px;
    background-color: #FFF;
    border-radius: 5px;
    color: #ccc;
  }
  .project .project-img {
    float: left;
    width: 25%;
    height: inherit;
    min-height: 250px;
    background: #ccc;
  }
  .project .zwippi-project-desc {
    float: left;
    width: calc( 75% - 20px);
    padding: 10px;
  }
  .project .zwippi-project-desc .title {
    font-size: 22px;
  }
  .project .zwippi-project-desc .desc {
    font-size: 16px;
  }
  .project .zwippi-project-desc span {
    font-weight: bold;
  }
  .project .zwippi-project-desc .rent-box {
    background-color: #eee;
    padding: 5px;
  }
  .project .details {
    font-size: 15px;
  }
  .project .sale {
    display: block;
    padding: 0;
    width: 100%;
  }
  .project .sale .contributions {
    float: left;
  }
  .project .sale .price {
    float: right;
  }
  .project .mini-progress-bar {
    width: 100%;
    height: 5px;
    background-color: #B00;
    position: relative;
  }
  .project .mini-progress-bar .mini-progress {
    width: 1%;
    height: 5px;
    background-color: #18cf00;
    position: absolute;
    top: 0;
    left: 0;
  }
  .project .zwippiProjectControl {
    float: right;
    width: 100%;
  }
  .project .cjBtn {
    display: block;
    text-decoration: none;
    width: 90px;
    margin: 5px;
    float: right;
    text-align: center;
    height: 40px;
    border-radius: 7px;
    line-height: 40px;
    background: #ccc;
    overflow: hidden;
  }
  .project .cjBtn:hover {
    display: block;
    text-decoration: none;
    width: 90px;
    margin: 5px;
    float: right;
    text-align: center;
    height: 40px;
    border-radius: 7px;
    line-height: 40px;
    background: #6C3;
    overflow: hidden;
  }
  .projectMenu {
    float: right;
    margin-top: 18px;
    font-size: 14px;
    display: block;
  }
  .projectMenu a {
    display: block;
    width: calc( 25% - 20px);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    float: left;
    padding: 10px;
    text-decoration: none;
  }
  .projectMenu a:hover {
    border-bottom: 1px solid #333;
    display: block;
    width: calc( 25% - 20px);
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    float: left;
    padding-bottom: 5px;
  }
  .projectCover {
    height: 400px;
    background: #CCC;
    width: 100%;
    position: relative;
  }
  .project-header {
    line-height: 30px;
  }
  .project-header .part_1 {
    float: left;
    width: calc( 50% - 10px);
    padding: 5px;
    text-align: left;
  }
  .project-header .part_2 {
    float: right;
    width: calc( 50% - 10px);
    padding: 5px;
    text-align: right;
  }
  .progress {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    height: 24px;
    overflow: hidden;
    position: relative;
    margin: 0px;
  }
  .progress #progressPercentage {
    display: block;
    line-height: 24px;
    text-align: center;
    color: #fff;
    width: 100%;
    font-size: 11px;
  }
  .progress .progressBar {
    width: 0%;
    background-color: rgba(29, 255, 104, 0.562);
    color: #fff;
    height: 24px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .descBar {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    height: 50px;
    overflow: hidden;
    position: absolute;
    margin: 0px;
    bottom: 0;
    left: 0;
  }
  .descBar .link {
    width: calc( 50% - 10px);
    float: right;
    color: #fff;
    height: 50px;
    padding: 5px;
    font-size: 12px;
    text-align: right;
  }
  .descBar .link a {
    color: #fff;
  }
  .descBar .desc {
    width: calc( 50% - 10px);
    float: left;
    color: #fff;
    height: 50px;
    padding: 5px;
    font-size: 12px;
    text-align: left;
  }
  .comment {
    width: auto;
    min-width: 250px;
    max-width: calc( 100% - 20px);
    padding: 5px;
    margin: 5px;
    float: left;
  }
  .comment .userimage {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #dcf3f5;
  }
  .comment .speechBubble {
    float: right;
    width: calc( 100% - 60px);
    padding: 5px;
    position: relative;
    background-color: #e6eff0;
    font-size: 10px;
    border-radius: 5px;
  }
  .comment .speechBubble::after {
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-color: #e6eff0;
    border-left: 0;
    margin-top: -10px;
    margin-left: -5px;
  }
  .comment .speechBubble .username {
    float: left;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 10px;
  }
  .comment .speechBubble .userdate {
    float: right;
    font-size: 10px;
  }
  .comment .speechBubble .delete {
    float: right;
    font-size: 10px;
    display: block;
    width: 50px;
  }
  .comment .speechBubble .messageArea {
    font-size: 12px;
    line-height: 18px;
    float: none;
    width: 100%;
    text-align: left;
  }
  .comment .speechBubble .reaction {
    font-size: 12px;
    line-height: 22px;
    float: none;
  }
  .comment .speechBubble .reaction a {
    display: block;
    float: right;
    width: 40px;
  }
  #infoBoard {
    border: 1px solid #EEE;
    padding: 0px;
  }
  .share-list {
    width: calc( 100% - 10px);
    float: left;
    padding: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    margin: 8px auto;
  }
  .share-list .photo {
    margin-right: 5px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
  }
  .share-list .part_0 {
    float: left;
    width: calc( 100% - 93px);
    padding: 4px;
    text-align: left;
    color: #222;
  }
  .share-list .part_0 h1 {
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
  }
  .mini-progress-bar {
    width: 100%;
    height: 2px;
    background-color: #00bb5d;
    position: relative;
    overflow: hidden;
    margin: 3px;
  }
  .mini-progress-bar .mini-progress-bar-fill {
    width: 1%;
    height: 2px;
    background-color: #fdda11;
    position: absolute;
    top: 0;
    left: 0;
  }
  .contribution {
    border-bottom: 1px solid #EEE;
    padding: 5px;
  }
  .contribution .part_1 {
    float: left;
    width: 25%;
    text-align: left;
  }
  .contribution .part_1 .userImage {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    float: left;
    background: #CCC;
    margin-right: 10px;
  }
  .contribution .part_2 {
    float: left;
    width: 25%;
  }
  .contribution .part_3 {
    float: left;
    width: 25%;
  }
  .contribution .part_4 {
    float: left;
    width: 25%;
  }
  .gazadaBtn {
    font-size: 9px;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    float: right;
    margin: 5px;
    background: #ccc;
    cursor: pointer;
    position: relative;
    border-radius: 100%;
  }
  .gazadaBtn:hover {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    float: right;
    margin: 5px;
    background: #6C9;
    cursor: pointer;
    position: relative;
    border-radius: 100%;
  }
  .gazadaBtn span {
    color: #fff;
    font-size: 11px;
    display: block;
    width: 25px;
    height: 25x;
    line-height: 25px;
    text-align: center;
    background: #6C9;
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -13px;
    border-radius: 100%;
  }
  .propCover {
    height: 250px;
    background: #CCC url(../img/propbg.jpg) no-repeat center center;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
  }
  .propDesc {
    width: 90%;
    border-left: 1px solid #B00;
    background: #FFF;
    padding: 10px;
    padding-left: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    display: none;
    color: #ccc;
  }
  .contract {
    width: 100%;
    float: left;
  }
  .contract a {
    font-family: alt-bold-font;
    color: #fff;
  }
  .contract a:hover {
    font-family: alt-bold-font;
    color: #fff;
  }
  .contract .tenant {
    width: calc( 50% - 10px);
    padding: 5px;
    float: left;
  }
  .contract .contract-info {
    width: calc( 50% - 11px);
    padding: 5px;
    float: left;
    border-left: 1px #fff solid;
  }
  .contract .rent-ctr {
    width: calc( 100% - 10px);
    float: left;
    padding: 5px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  .contract .rent-history {
    width: calc(  100% - 10px);
    float: left;
    padding: 5px;
  }
  .contract .rent-done {
    width: calc(  100% - 10px);
    background-color: rgba(255, 255, 255, 0.3);
    float: left;
    padding: 5px;
  }
  .contract .rent-new {
    width: calc(  100% - 10px);
    background-color: rgba(255, 255, 255, 0);
    float: left;
    padding: 5px;
  }
  /* Admin*/
  .dashboard {
    margin: 10px auto;
    width: 100%;
    float: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .dashboard .section {
    width: calc( 25% - 10px);
    float: left;
    padding: 5px;
  }
  .dashboard .section p {
    color: #FFF;
    text-align: center;
    width: 100%;
  }
  .dashboard .section p .rands {
    color: #FFF;
  }
  .dashboard .section p .cents {
    color: #FFF;
  }
  .dashboard .section h1 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
  }
  .searchbar {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    height: 40px;
    padding: 5px;
    color: #fff;
    padding-right: 45px;
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
  }
  .user-result {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-bottom: 1px solid #fff;
  }
  .user-result .user-image {
    margin-right: 1px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin-top: 6px;
  }
  .user-result .user-details {
    float: left;
    width: calc( 100% - 121px);
  }
  .user-result .user-operations a {
    margin: 5px;
    color: #fff;
    width: 35px;
    display: inline-block;
  }
  .user-result .user-operations a:hover {
    margin: 5px;
    color: #fff;
    width: 35px;
  }
  .admin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    padding: 0px;
  }
  .admin .admin-menu {
    width: 200px;
  }
  .admin .admin-menu lu {
    clear: both;
    text-indent: none;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
  }
  .admin .admin-menu li {
    clear: both;
    text-indent: none;
    counter-increment: section;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    -webkit-transition: background .45s ease;
    transition: background .45s ease;
  }
  .admin .admin-menu li:hover {
    text-indent: none;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    background: #B00;
    color: #FFF;
  }
  .admin .admin-menu li a::before {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    margin-left: 6px;
    content: '';
    font-size: 9px;
    color: #a5afba;
    line-height: 30px;
    display: inline-block;
  }
  .admin .admin-menu li a {
    text-decoration: none;
    color: #221f1f;
    display: block;
    width: 100%;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
  }
  .admin .admin-menu li a:hover {
    text-decoration: none;
    color: #fff;
    display: block;
    width: 100%;
  }
  .admin .admin-content {
    width: calc( 100% - 240px);
    padding: 10px;
    min-height: 100vh;
    border-left: 1px solid #ccc;
  }
  .wrapper {
    width: 100%;
    margin: 0px;
    min-height: 100vh;
    padding: 0px;
    color: #eee;
    position: relative;
  }
  .content-wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0px auto;
    height: auto;
    padding: 0px;
    color: #eee;
  }
  .content-wrapper p, .content-wrapper ol {
    line-height: 28px;
  }
  .item-header {
    color: #222;
    width: 100%;
    float: left;
    height: 130px;
    margin-bottom: 10px;
  }
  .item-header .moneyH1 {
    font-family: alt-bold-font;
    color: #FFF;
  }
  .item-header .moneyH2 {
    font-family: alt-bold-font;
    color: #3B6;
  }
  .item-header .moneyH3 {
    font-family: alt-bold-font;
    color: #FB0;
  }
  .item-header .moneyH4 {
    font-family: alt-bold-font;
    color: #ff004c;
  }
  .item-header .item_back {
    width: 40px;
    float: left;
    text-align: center;
  }
  .item-header .item_back a {
    font-size: 30px;
    color: rgba(255, 255, 255, 0.5);
  }
  .item-header .item_back a:hover {
    color: white;
  }
  .item-header .item_back .sale-status {
    display: none;
    width: 38px;
    margin: 10px auto;
    border-radius: 5px;
    text-align: center;
    background-color: #b00;
    font-size: 28px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .item-header .item_back .sale-status .mini-label {
    font-size: 10px;
    text-transform: uppercase;
  }
  .item-header .item_ui {
    width: 130px;
    padding: 5px;
    float: left;
    border-right: 1px solid #fff;
  }
  .item-header .item_ui .topbar {
    color: #fff;
    display: block;
    text-align: center;
    float: none;
  }
  .item-header .item_ui .bottombar {
    color: #fff;
    display: block;
    text-align: center;
    float: none;
  }
  .item-header .item_ui .item_cover {
    position: relative;
    margin: 5px auto;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 100%;
    background-color: #6CE;
    background-size: cover;
    text-align: center;
    display: block;
    float: none;
  }
  .item-header .item_player {
    width: calc( 100% - 196px);
    padding: 5px;
    float: left;
    line-height: auto;
    margin-right: 5px;
  }
  .item-header .item_player .price {
    margin: 2px;
    padding: 2px;
    float: right;
    font-size: 12px;
    color: #eee;
    text-transform: uppercase;
  }
  .item-header .item_player .collection {
    margin: 2px;
    padding: 2px;
    float: left;
    font-size: 12px;
    color: #eee;
    text-transform: uppercase;
  }
  .item-header .item_player .rent {
    margin: 2px;
    padding: 2px;
    float: right;
    font-size: 12px;
    color: #eee;
  }
  .item-header .item_player .pwallet {
    margin: 2px;
    padding: 2px;
    float: left;
    font-size: 12px;
    color: #eee;
  }
  .item-header .item_player #my_item_wallet {
    color: #fff;
    display: block;
    font-size: 32px;
    font-family: alt-bold-font;
  }
  .item-header .item_player #item_name {
    text-transform: uppercase;
    display: block;
    margin-bottom: 3px;
  }
  .item-header .item_player #pwallet {
    color: #fff;
    text-transform: uppercase;
    display: block;
  }
  .item-header .item_player .contributeBtn {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 11px;
    text-align: center;
    margin: 2px;
    float: left;
    background-color: #e9c810;
    color: #FFF;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease, font-size 0.1s 0s;
    transition: background-color 0.2s ease, font-size 0.1s 0s;
    margin-top: 10px;
  }
  .item-header .item_player .contributeBtn:hover {
    width: 22px;
    height: 22px;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin: 2px;
    float: left;
    background-color: #fff;
    color: #FFF;
    border-radius: 100%;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease, font-size 0.1s 0s;
    transition: background-color 0.2s ease, font-size 0.1s 0s;
    margin-top: 10px;
  }
  header {
    background-color: transparent;
    float: left;
    width: 100%;
    height: auto;
  }
  header .logo {
    display: block;
    background: url("../img/svg/logo.svg") no-repeat left center;
    background-size: 100%;
    float: left;
    height: 50px;
    width: 150px;
    cursor: pointer;
  }
  header .logo:hover {
    display: block;
    background: url("../img/svg/logo.svg") no-repeat left center;
    background-size: 110%;
    float: left;
    height: 50px;
    width: 150px;
    cursor: pointer;
  }
  header .home-menu {
    float: right;
    width: auto;
    display: block;
  }
  header .home-menu a {
    position: relative;
    text-transform: uppercase;
    display: block;
    width: 80px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #222;
    text-decoration: none;
    float: left;
    font-size: 15px;
    border-bottom: transparent 0px solid;
    -webkit-transition: border-bottom 0.15s ease-in;
    transition: border-bottom 0.15s ease-in;
  }
  header .home-menu a:hover {
    border-bottom: #222 1px solid;
    text-transform: uppercase;
    display: block;
    width: 80px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #222;
    float: left;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: border-bottom 0.15s ease-in;
    transition: border-bottom 0.15s ease-in;
  }
  header .responsive-menu {
    height: 50px;
    width: 50px;
    float: right;
    display: none;
    color: #222;
    margin-top: 15px;
  }
  header .responsive-menu a {
    color: #222;
  }
  header .responsive-menu a:hover {
    color: #222;
  }
  header .home-art {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s ease 0.15s, opacity 0.10s 0s, height 0.12s;
    transition: visibility 0s ease 0.15s, opacity 0.10s 0s, height 0.12s;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    height: 0;
    background: #000 url("../img/art.png") no-repeat center center;
    background-size: contain;
  }
  header .home-art-show {
    opacity: 1;
    visibility: visible;
    -webkit-transition: visibility 0s ease 0s, opacity 0.25s 0s, height 0.25s;
    transition: visibility 0s ease 0s, opacity 0.25s 0s, height 0.25s;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    height: 350px;
    background: #000 url("../img/art.png") no-repeat center center;
    background-size: contain;
    margin: 20px auto;
    -webkit-transition: height .2s ease .1s;
    transition: height .2s ease .1s;
  }
  .info {
    color: #ccc;
    width: calc( 100% - 20px);
    margin: 0 auto;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .info h1 {
    color: #fff;
    font-family: alt-font;
    font-size: 36px;
    margin: 0px;
    padding: 0px;
  }
  .info h2 {
    color: #ccc;
    font-family: alt-bold-font;
    font-size: 22px;
  }
  .info h3 {
    color: #ccc;
    font-family: alt-font;
    font-size: 18px;
  }
  .info p, .info ol {
    line-height: 32px;
  }
  .info img {
    width: 100%;
    max-width: 500px;
  }
  /* 04  home info  */
  .zwippi-tv {
    background: #fff;
    color: #ccc;
    width: 100%;
    height: 400px;
    border-radius: 11px;
    margin-top: 10px;
    overflow: hidden;
  }
  .public-stats {
    float: left;
    width: calc( 100% - 10px);
    padding: 5px;
  }
  .public-stats .part_1 {
    float: left;
    width: calc( 100% - 10px);
    padding: 5px;
    text-align: center;
  }
  .play_box {
    width: calc( 100% - 40px);
    margin: 30px auto;
    padding: 2px 10px 2px 10px;
    /*border-bottom: $border-size solid $theme-color;*/
    position: relative;
    min-height: 90px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
  }
  .play_box .playerName {
    display: block;
    margin-top: 15px;
    font-size: 16px;
    font-family: alt-bold-font;
  }
  .play_box a {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  .play_box a:hover {
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
  }
  .play_box .provinceName {
    font-size: 11px;
    display: block;
  }
  .play_box .part_1 {
    width: calc( 50% - 70px);
    margin: 5px;
    padding: 5px;
    float: left;
    line-height: 18px;
  }
  .play_box .part_1 .userimage {
    margin-right: 1px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    background-color: #222;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    margin: 5px;
    border: 2px solid #222;
  }
  .play_box .part_2 {
    width: 60px;
    height: 60px;
    padding: 10px;
    float: left;
    text-align: center;
  }
  .play_box .part_2 .amountArt {
    width: 40px;
    height: 40px;
    margin: 5px auto;
    border-radius: 100%;
    background-color: #808E9D;
    position: relative;
    border: 2px solid #222;
  }
  .play_box .part_2 .amount {
    position: absolute;
    top: -22px;
    left: 0;
    width: 50px;
    font-size: 10px;
    font-weight: bold;
    margin: 5px auto;
    text-align: center;
    color: #222;
    display: block;
  }
  .play_box .part_2 .date {
    font-size: 11px;
    display: block;
    position: absolute;
    bottom: -17px;
    left: -15px;
    width: 80px;
    text-align: center;
  }
  .play_box .part_2 .indicator_left {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 11px;
    left: -25px;
    border-radius: 100%;
    background-color: #CCC;
    border: 2px solid #222;
  }
  .play_box .part_2 .indicator_right {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 11px;
    right: -25px;
    border-radius: 100%;
    background-color: #CCC;
    border: 2px solid #222;
  }
  .play_box .part_3 {
    width: calc( 50% - 70px);
    margin: 5px;
    padding: 5px;
    float: right;
    text-align: right;
    line-height: 18px;
  }
  .play_box .part_3 .otheruserImage {
    margin-right: 1px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 100%;
    background-color: #222;
    background-size: cover;
    text-align: center;
    display: block;
    float: right;
    margin: 5px;
    border: 2px solid #222;
  }
  .canceled {
    position: absolute;
    width: 100%;
    height: 90px;
    text-align: center;
    line-height: 90px;
    background-color: forestgreen;
    color: #FFF;
    font-size: 24px;
    float: left;
  }
  /* 04 user */
  .profileUserImage {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    margin: 0 auto;
    background: #CCC;
    position: relative;
  }
  .profileUserImage .percent {
    position: relative;
    width: 150px;
    height: 150px;
  }
  .profileUserImage .percent svg {
    position: relative;
    width: 150px;
    height: 150px;
  }
  .profileUserImage .percent svg circle {
    width: 150px;
    height: 150px;
    fill: none;
    stroke-width: 10px;
    stroke: #6ae902;
    -webkit-transform: translate(5px, 5px);
            transform: translate(5px, 5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    stroke-linecap: round;
  }
  .profileUserImage .percent svg circle:nth-child(1) {
    stroke: #e6ffd2;
    stroke-dashoffset: 0;
  }
  .profileUserImage .percent svg circle:nth-child(2) {
    stroke: #6ae902;
    stroke-dashoffset: calc( 440 - (440 *  0 ) / 100);
  }
  .private-bg-color {
    background-color: #FFF;
  }
  .userDetails {
    color: #eee;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }
  .userDetails .userImage {
    width: 58px;
    height: 58px;
    border: 2px solid #444;
    border-radius: 100%;
    float: left;
    background: #CCC;
    margin-right: 10px;
    position: relative;
  }
  .userDetails .userImage .admin-indicator {
    border-radius: 100%;
    position: absolute;
    width: 15px;
    height: 15px;
    font-size: 7px;
    text-align: center;
    line-height: 15px;
    background-color: #EEE;
    color: #eee;
    top: -20px;
    left: -15px;
    display: none;
  }
  .userDetails .userInfo {
    height: 60px;
    border-radius: 100%;
    float: left;
    line-height: 25px;
    text-decoration: none;
    color: #eee;
  }
  .userDetails .username {
    font-size: 14px;
    font-family: alt-bold-font;
    text-transform: capitalize;
    text-decoration: none;
    color: #eee;
  }
  .userDetails .username:hover {
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: underline;
  }
  .userDetails .wallet-icon {
    margin-right: 1px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
    font-size: 9px;
    color: #eee;
    margin-top: 6px;
  }
  .userDetails .userMoney {
    font-size: 24px;
    text-decoration: none;
    font-family: alt-bold-font;
    color: #eee;
  }
  .userDetails .userMoney:hover {
    font-size: 24px;
    text-decoration: underline;
  }
  .userDetails #rands {
    font-size: 24px;
    color: #eee;
  }
  .userDetails #cents {
    font-size: 11px;
    vertical-align: super;
    color: #eee;
  }
  #cpayment {
    display: block;
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
  }
  #cpayment lu {
    position: relative;
    display: block;
    width: calc( 100% - 20px);
  }
  #cpayment lu li {
    border-radius: 15px;
    display: block;
    width: calc( 100% - 20px);
    padding: 10px;
    margin-top: 10px;
    background-color: #eee;
    line-height: 60px;
    margin-left: 10px;
  }
  #cpayment lu li:hover {
    border-radius: 15px;
    display: block;
    width: calc( 100% - 20px);
    padding: 10px;
    margin-top: 10px;
    background-color: #75bfe0;
    line-height: 60px;
    margin-left: 10px;
  }
  #cpayment .graphic {
    font-weight: bold;
    display: block;
    float: left;
    width: 60px;
    height: 60px;
    background-color: #09F;
    text-align: center;
    line-height: 60px;
    border-radius: 100%;
  }
  .clearTextfx {
    text-decoration: none;
  }
  .clearTextfx:hover {
    text-decoration: none;
  }
  .game-btn {
    width: 100%;
    margin-bottom: 5px;
    text-decoration: none;
    color: #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .game-btn .game-ctrl {
    width: 75%;
  }
  .game-btn .money-ctrl {
    width: 25%;
  }
  .game-btn a {
    font-family: alt-bold-font;
    color: #eee;
    display: block;
    width: 50px;
    height: 45px;
    float: left;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    text-decoration: none;
    position: relative;
  }
  .game-btn a .icon {
    display: block;
    border: 2px solid #eee;
    color: #eee;
    color: #eee;
    opacity: 1;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    text-align: center;
    margin: 0 auto;
  }
  .game-btn a .counter {
    display: none;
    background-color: #B00;
    color: #fff;
    border: 0;
    opacity: 1;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 9px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .game-btn a:hover {
    display: block;
    width: 50px;
    height: 45px;
    float: left;
    text-align: center;
    text-transform: uppercase;
    font-size: 9px;
    text-decoration: none;
    color: #eee;
  }
  .game-btn a:hover .icon {
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    border: 2px solid #eee;
    opacity: 1;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
  }
  .top-up-denominator {
    width: calc( 100% - 10px);
    padding: 5px;
    float: left;
    background-color: #cfe8f8;
    border-radius: 5px;
  }
  .top-up-denominator img {
    margin-right: 1px;
    width: 75px;
    height: 75px;
    line-height: 75px;
    border-radius: 100%;
    background-color: #999;
    background-size: cover;
    text-align: center;
    display: block;
    float: left;
  }
  .top-up-denominator .description {
    float: left;
    padding: 5px;
    width: calc(100% - 204px);
  }
  .top-up-denominator .checkout-button {
    border-radius: 3px;
    border: 0;
    margin-top: 15px;
    float: right;
    width: 90px;
    background-color: #666;
    color: #fff;
    padding: 5px;
    cursor: pointer;
  }
  .top-up-denominator .checkout-button:hover {
    border-radius: 3px;
    border: 0;
    margin-top: 15px;
    float: right;
    width: 90px;
    background-color: #09F;
    color: #fff;
    padding: 5px;
    cursor: pointer;
  }
  .history {
    width: calc( 100% - 20px);
    float: left;
    padding: 5px;
    margin: 5px;
    background: #F8F8F8;
    border-radius: 30px;
    color: #222;
  }
  .history .part_1A {
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 100%;
    background: #0C3;
    margin-right: 10px;
    line-height: 30px;
    color: #FFF;
    text-align: center;
  }
  .history .part_1B {
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 100%;
    background: #B00;
    margin-right: 10px;
    line-height: 30px;
    color: #FFF;
    text-align: center;
  }
  .history .part_2 {
    width: calc( 50% - 20px);
    float: left;
    line-height: 30px;
  }
  .history .part_3 {
    width: calc( 50% - 20px);
    height: 30px;
    float: left;
    line-height: 30px;
    text-align: right;
  }
  .history .part_3 span {
    display: block;
    background: #09F;
    border-radius: 30px;
    float: right;
    margin-left: 10px;
    color: #FFF;
    width: 100px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    line-height: 30px;
  }
  .loadMore {
    display: block;
    background: #999;
    border-radius: 30px;
    margin: 10px auto;
    color: #FFF;
    width: 100px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    line-height: 30px;
  }
  .loadMore:hover {
    display: block;
    background: #093;
    border-radius: 30px;
    margin: 10px auto;
    color: #FFF;
    width: 100px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    line-height: 30px;
  }
  .usercount {
    margin-top: 24px;
    margin-right: 8px;
    color: #eee;
    display: block;
    float: right;
    width: 120px;
    text-align: right;
  }
  .usercount .heading {
    color: #eee;
  }
  .usercount #players {
    color: #eee;
  }
  .usercount .usermark {
    background-color: #2ff807;
    display: inline-block;
    width: 9px;
    height: 9px;
    border: 1px solid #333;
    margin-right: 3px;
    border-radius: 100%;
  }
  .ptid {
    float: right;
    padding: 5px;
    display: block;
    width: 200px;
    text-align: center;
    background-color: #0C3;
  }
  .history {
    width: calc( 100% - 20px);
    float: left;
    padding: 5px;
    margin: 5px;
    background: #F8F8F8;
    border-radius: 30px;
  }
  .history .part_1A {
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 100%;
    background: #0C3;
    margin-right: 10px;
    line-height: 30px;
    color: #FFF;
    text-align: center;
  }
  .history .part_1B {
    width: 30px;
    height: 30px;
    float: left;
    border-radius: 100%;
    background: #B00;
    margin-right: 10px;
    line-height: 30px;
    color: #FFF;
    text-align: center;
  }
  .history .part_2 {
    width: calc( 50% - 20px);
    float: left;
    line-height: 30px;
  }
  .history .part_3 {
    width: calc( 50% - 20px);
    height: 30px;
    float: left;
    line-height: 30px;
    text-align: right;
  }
  .history .part_3 span {
    display: block;
    background: #09F;
    border-radius: 30px;
    float: right;
    margin-left: 10px;
    color: #FFF;
    width: 100px;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    line-height: 30px;
  }
  .zwippi-content {
    width: calc( 100% - 16px);
    padding: 8;
    max-width: 1000px;
    margin: 0px auto;
  }
  .zwippi-content p, .zwippi-content ol {
    line-height: 32px;
  }
  .zwippi-content img {
    width: 100%;
    max-width: 500px;
  }
  .messanger {
    z-index: 10;
    position: fixed;
    left: 0;
    top: -2px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transition: visibility .55s ease-in, opacity 0.55s ease, -webkit-transform 0.25s ease-in;
    transition: visibility .55s ease-in, opacity 0.55s ease, -webkit-transform 0.25s ease-in;
    transition: visibility .55s ease-in, opacity 0.55s ease, transform 0.25s ease-in;
    transition: visibility .55s ease-in, opacity 0.55s ease, transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  }
  .messanger-show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: visibility .25s ease-out, opacity 0.55s ease-in, -webkit-transform 0.25s ease-in;
    transition: visibility .25s ease-out, opacity 0.55s ease-in, -webkit-transform 0.25s ease-in;
    transition: visibility .25s ease-out, opacity 0.55s ease-in, transform 0.25s ease-in;
    transition: visibility .25s ease-out, opacity 0.55s ease-in, transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  }
  .messanger-content {
    border-radius: 2rem;
    position: absolute;
    bottom: 0;
    right: 50%;
    background-color: white;
    height: 120px;
    width: 120px;
    -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 1.5s linear, opacity .5s ease, right 1s ease, width .3s ease, height .2s ease, -webkit-transform 0.25s;
    transition: visibility 1.5s linear, opacity .5s ease, right 1s ease, width .3s ease, height .2s ease, -webkit-transform 0.25s;
    transition: visibility 1.5s linear, opacity .5s ease, transform 0.25s, right 1s ease, width .3s ease, height .2s ease;
    transition: visibility 1.5s linear, opacity .5s ease, transform 0.25s, right 1s ease, width .3s ease, height .2s ease, -webkit-transform 0.25s;
  }
  .messanger-content .messanger-text {
    margin: 10px auto;
    width: calc( 100% - 20px);
    padding: 5px;
    display: block;
    height: 60px;
  }
  .messanger-content button {
    border-radius: 5px;
    background: #666;
    color: #CCC;
    padding: 8px;
    float: right;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 8px;
  }
  .messanger-content button:hover {
    border-radius: 5px;
    background: #B00;
    color: #CCC;
    padding: 8px;
    float: right;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 8px;
  }
  .messanger-close-button {
    float: right;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    background-color: lightgray;
    margin: 10px;
  }
  .messanger-close-button:hover {
    background-color: #B00;
    color: #FFF;
  }
  .messanger-content-show {
    z-index: 12;
    border-radius: 2rem;
    position: absolute;
    bottom: 100px;
    right: 50%;
    -webkit-transform: translate(50%, -15px);
            transform: translate(50%, -15px);
    height: 180px;
    width: 320px;
    background-color: white;
    visibility: visible;
    opacity: 1;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease, -webkit-transform 0.25s;
  }
  .messanger-content::after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #FFF;
    border-bottom: 0;
    margin-left: -15px;
    margin-bottom: -15px;
  }
  .errorHeader {
    text-transform: uppercase;
    background: #FEE;
    color: #B00;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin-top: 30px;
  }
  .error {
    border-bottom: 1px solid #B00;
    color: #B00;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    margin-top: 5px;
  }
  .error-message {
    padding: 15px;
    line-height: 30px;
  }
  .success-header {
    text-transform: uppercase;
    background: #CFE;
    color: #0C3;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin-top: 30px;
  }
  .success {
    border-bottom: 1px solid #0C3;
    color: #0C3;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    margin-top: 5px;
  }
  .success-message {
    padding: 15px;
    line-height: 30px;
  }
  /* bank */
  .bank {
    width: calc( 100% - 20px);
    max-width: 500px;
    padding: 10px;
    margin: 5px auto;
    background: #FFF;
    line-height: 30px;
    border-radius: 13px;
    color: #222;
  }
  .bank .bankLogo {
    width: 50px;
    height: 50px;
    background: #CCC;
    border-radius: 100%;
    float: left;
  }
  .bank .part_1 {
    width: calc( 100% - 50px);
    float: left;
    color: #222;
  }
  .bank .part_1 span {
    width: 150px;
    float: left;
    text-align: right;
    margin-right: 10px;
    color: #000;
  }
  .bank .line {
    width: 100%;
    float: left;
  }
  /*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  
  body{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1D212B;
  }*/
  .img-slider {
    position: relative;
    width: 800px;
    height: 500px;
    margin: 10px;
    background: #1D212B;
  }
  .img-slider .slide {
    z-index: 1;
    position: absolute;
    width: 100%;
    -webkit-clip-path: circle(0% at 0 50%);
            clip-path: circle(0% at 0 50%);
  }
  .img-slider .slide.active {
    -webkit-clip-path: circle(150% at 0 50%);
            clip-path: circle(150% at 0 50%);
    -webkit-transition: 2s;
    transition: 2s;
    -webkit-transition-property: -webkit-clip-path;
    transition-property: -webkit-clip-path;
    transition-property: clip-path;
    transition-property: clip-path, -webkit-clip-path;
  }
  .img-slider .slide img {
    z-index: 1;
    width: 100%;
    border-radius: 5px;
  }
  .img-slider .slide .info {
    position: absolute;
    top: 0;
    padding: 15px 30px;
  }
  .img-slider .slide .info h2 {
    color: #fff;
    font-size: 45px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .img-slider .slide .info p {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    font-size: 16px;
    width: 60%;
    padding: 10px;
    border-radius: 4px;
  }
  .img-slider .navigation {
    z-index: 2;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .img-slider .navigation .btn {
    background: rgba(255, 255, 255, 0.5);
    width: 12px;
    height: 12px;
    margin: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  .img-slider .navigation .btn.active {
    background: #2696E9;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (min-width: 1058px) and (max-width: 820px) {
  .img-slider {
    width: 600px;
    height: 375px;
  }
  .img-slider .slide .info {
    padding: 10px 25px;
  }
  .img-slider .slide .info h2 {
    font-size: 35px;
  }
  .img-slider .slide .info p {
    width: 70%;
    font-size: 15px;
  }
  .img-slider .navigation {
    bottom: 25px;
  }
  .img-slider .navigation .btn {
    width: 10px;
    height: 10px;
    margin: 8px;
  }
}

@media only screen and (min-width: 1058px) and (max-width: 620px) {
  .img-slider {
    width: 400px;
    height: 250px;
  }
  .img-slider .slide .info {
    padding: 10px 20px;
  }
  .img-slider .slide .info h2 {
    font-size: 30px;
  }
  .img-slider .slide .info p {
    width: 80%;
    font-size: 13px;
  }
  .img-slider .navigation {
    bottom: 15px;
  }
  .img-slider .navigation .btn {
    width: 8px;
    height: 8px;
    margin: 6px;
  }
}

@media only screen and (min-width: 1058px) and (max-width: 420px) {
  .img-slider {
    width: 320px;
    height: 200px;
  }
  .img-slider .slide .info {
    padding: 5px 10px;
  }
  .img-slider .slide .info h2 {
    font-size: 25px;
  }
  .img-slider .slide .info p {
    width: 90%;
    font-size: 11px;
  }
  .img-slider .navigation {
    bottom: 10px;
  }
}

/* tablet UI*/
@media only screen and (min-width: 501px) and (max-width: 1057px) {
  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-decoration: none;
  }
  .header .brand-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: 50%;
    max-width: 50%;
    margin: 0px;
  }
  .header .brand-box .brand {
    width: 80px;
    height: 80px;
    background: #3c4c5c url(../img/logo_1.jpg) no-repeat center top;
    background-size: 100%;
    margin: 0px;
  }
  .header .user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: calc( 50% - 6px);
    max-width: calc( 50% - 6px);
    margin-top: 3px;
    text-decoration: none;
  }
  .header .user .user-image {
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #333;
  }
  .header .user .user-info {
    display: block;
    height: 65px;
    padding-top: 6px;
    padding-left: 4px;
  }
  .header .user .user-info a {
    text-decoration: none;
  }
  .header .user .user-info a:visited {
    text-decoration: none;
  }
  .header .user .user-info .firstname {
    font-size: 1rem;
    text-decoration: none;
  }
  .header .user .user-info .namelast {
    font-size: .1rem;
    text-decoration: none;
  }
  .header .user .user-info .wallet {
    font-size: 1.5rem;
    font-family: alt-bold-font;
    text-decoration: none;
  }
  .header .user .user-info .wallet .rands {
    font-size: 1.5rem;
  }
  .header .user .user-info .wallet sup {
    vertical-align: super;
    text-decoration: none;
    font-size: .7rem;
  }
  .header .user .user-info .wallet .cents {
    font-size: .7rem;
    text-decoration: none;
  }
  .header .menu {
    width: calc( 50% - 20px);
    text-align: right;
    margin: 10px;
    text-transform: capitalize;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
  }
  .header .menu .mobile-menu-btn {
    display: block !important;
    width: 100px;
    height: 50px;
    line-height: 50px;
    margin-top: 0px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: #3c4c5c;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  .header .menu .mobile-menu-btn:hover {
    color: #3c4c5c;
    border-top: 5px solid #d4a300;
  }
  .header .menu .mobile-menu-btn:visited {
    text-decoration: none;
  }
  .header .menu a {
    display: none;
    width: 100px;
    height: 50px;
    line-height: 50px;
    margin-top: 22px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: #3c4c5c;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
  }
  .header .menu a:hover {
    color: #3c4c5c;
    border-top: 5px solid #d4a300;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .header .menu a:visited {
    text-decoration: none;
  }
  .header .menu .private-menu {
    margin-top: 22px;
    padding: 0px;
    padding-top: 10px;
    text-decoration: none;
    font-size: 30px;
    color: #666;
  }
  .header .menu .private-menu:hover {
    color: #222;
  }
  .dropbtn {
    text-decoration: none;
    color: #FFF !important;
    background: #333;
    padding: 16px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    min-width: 160px;
  }
  .dropdown:hover .dropbtn {
    background: #3c4c5c !important;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9 !important;
    min-width: 160px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 30;
  }
  .dropdown-content a {
    text-align: left !important;
    color: black;
    padding: 12px 16px !important;
    text-decoration: none;
    display: block !important;
    margin: 0 !important;
  }
  .dropdown-content a:hover {
    background-color: #f1f1f1 !important;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .video-box {
    width: 100%;
    height: 450px;
    background-color: #000;
  }
  .home-box2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .home-box2 .box1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: 50%;
    max-width: 50%;
    margin: 0px;
  }
  .home-box2 .box1 a {
    color: #3c4c5c;
  }
  .sideMenu {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: -2px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    border: #900 2px solid;
    -webkit-transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  }
  .sideMenu-show {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in, -webkit-transform 0.12s ease-in;
  }
  .sideMenu-content {
    position: fixed;
    height: 100%;
    top: 0;
    right: -230px;
    background-color: #fff;
    width: 230px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
    transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
    transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease;
    transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease, -webkit-transform 0.15s;
  }
  .sideMenu-content-show {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0px;
    background-color: white;
    width: 230px;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease, -webkit-transform 0.25s;
  }
  .sideMenuBrand {
    float: left;
    width: 100px;
    line-height: 1.5rem;
    text-align: left;
    margin: 10px;
    text-transform: uppercase;
    content: 'IBIN';
    color: #333;
    font-family: zwippi-font;
  }
  .sideMenu-close-button {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    background-color: lightgray;
    margin: 10px;
    margin-right: 30px;
  }
  .sideMenu-close-button:hover {
    background-color: #3c4c5c;
    color: #FFF;
  }
  .sideMenu-close-button-general {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    background-color: #FFF;
    margin: 3px;
    color: #A5AFBA;
  }
  .sideMenu-close-button-general:hover {
    background-color: #808E9D;
  }
  #sideMenuControlheader {
    margin-top: 42px;
    text-align: center;
    font-size: 24px;
    font-family: zwippi-font;
    text-align: center;
    border-top: 1px rgba(165, 175, 186, 0.5) solid;
  }
  #sideMenuControlheader lu {
    clear: both;
    text-indent: none;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
  }
  #sideMenuControlheader li {
    clear: both;
    text-indent: none;
    counter-increment: section;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    -webkit-transition: background .45s ease;
    transition: background .45s ease;
  }
  #sideMenuControlheader li:hover {
    text-indent: none;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    background: #3c4c5c;
    color: #FFF;
  }
  #sideMenuControlheader li a::before {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    margin-left: 6px;
    content: '';
    font-size: 9px;
    color: #a5afba;
    line-height: 30px;
    display: inline-block;
  }
  #sideMenuControlheader li a {
    text-decoration: none;
    color: #221f1f;
    display: block;
    width: 100%;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
  }
  #sideMenuControlheader li a:hover {
    text-decoration: none;
    color: #fff;
    display: block;
    width: 100%;
  }
  /* Slider */
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }
  /* Icons */
  @font-face {
    font-family: "slick";
    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  .slick-prev:hover:before, .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: -25px;
  }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
  }
  .slick-prev:before {
    content: "←";
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }
  .slick-next {
    right: -25px;
  }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
  }
  .slick-next:before {
    content: "→";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
  }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table;
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .responsive-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .responsive-wrapper .prevBtn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    font-size: 22px;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: transparent;
    border: 0;
    color: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: 40px;
  }
  .responsive-wrapper .nextBtn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 50%;
    background-color: transparent;
    border: 0;
    color: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-right: 40px;
  }
  .responsive {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    display: block;
    background-color: #ccc;
    margin-top: 15px;
  }
  .responsive .slider {
    display: block;
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .responsive .slider img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .responsive .slider .slider-content {
    bottom: -5px;
    height: 100vh;
    margin: 0;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: 50%;
    text-align: right;
  }
  .responsive .slider .slider-content .slide-text {
    display: block;
    width: 180px;
    position: absolute;
    right: 10px;
    top: 50%;
    line-height: 25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .responsive .slider .slider-content .slide-text .bt1 {
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .responsive .slider .slider-content .slide-text .bt2 {
    font-size: 30px;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .responsive .slider .slider-content .slide-text .summary {
    font-size: 14px;
    color: #fff;
  }
  .footer {
    position: relative;
  }
  .content {
    display: block;
  }
  .content .layoutBy2 {
    width: calc( 100% - 20px);
    padding: 10px;
    margin-bottom: 5px;
  }
  .content .layoutBy2 p {
    line-height: 28px;
  }
  .content .layoutBy2_bottom {
    width: calc( 100% - 20px);
    padding: 10px;
    margin-bottom: 120px;
  }
  .content .layoutBy2Art {
    display: none;
  }
  .content .mobile_art {
    height: 240px;
    display: block;
  }
}

/* mobile UI*/
@media only screen and (max-width: 500px) {
  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-decoration: none;
  }
  .header .brand-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: 50%;
    max-width: 50%;
    margin: 0px;
  }
  .header .brand-box .brand {
    width: 80px;
    height: 80px;
    background: #3c4c5c url(../img/logo_1.jpg) no-repeat center top;
    background-size: 100%;
    margin: 0px;
  }
  .header .user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: calc( 50% - 6px);
    max-width: calc( 50% - 6px);
    margin-top: 3px;
    text-decoration: none;
  }
  .header .user .user-image {
    display: block;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #333;
  }
  .header .user .user-info {
    display: block;
    height: 65px;
    padding-top: 6px;
    padding-left: 4px;
  }
  .header .user .user-info a {
    text-decoration: none;
  }
  .header .user .user-info a:visited {
    text-decoration: none;
  }
  .header .user .user-info .firstname {
    font-size: 1rem;
    text-decoration: none;
  }
  .header .user .user-info .namelast {
    font-size: .1rem;
    text-decoration: none;
  }
  .header .user .user-info .wallet {
    font-size: 1.5rem;
    font-family: alt-bold-font;
    text-decoration: none;
  }
  .header .user .user-info .wallet .rands {
    font-size: 1.5rem;
  }
  .header .user .user-info .wallet sup {
    vertical-align: super;
    text-decoration: none;
    font-size: .7rem;
  }
  .header .user .user-info .wallet .cents {
    font-size: .7rem;
    text-decoration: none;
  }
  .header .menu {
    width: calc( 50% - 20px);
    text-align: right;
    margin: 10px;
    text-transform: capitalize;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
  }
  .header .menu .mobile-menu-btn {
    display: block !important;
    width: 100px;
    height: 50px;
    line-height: 50px;
    margin-top: 0px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: #3c4c5c;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  .header .menu .mobile-menu-btn:hover {
    color: #3c4c5c;
    border-top: 5px solid #d4a300;
  }
  .header .menu .mobile-menu-btn:visited {
    text-decoration: none;
  }
  .header .menu a {
    display: none;
    width: 100px;
    height: 50px;
    line-height: 50px;
    margin-top: 22px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: #3c4c5c;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
  }
  .header .menu a:hover {
    color: #3c4c5c;
    border-top: 5px solid #d4a300;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  .header .menu a:visited {
    text-decoration: none;
  }
  .header .menu .private-menu {
    margin-top: 22px;
    padding: 0px;
    padding-top: 10px;
    text-decoration: none;
    font-size: 30px;
    color: #666;
  }
  .header .menu .private-menu:hover {
    color: #222;
  }
  .dropbtn {
    text-decoration: none;
    color: #FFF !important;
    background: #333;
    padding: 16px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    min-width: 160px;
  }
  .dropdown:hover .dropbtn {
    background: #3c4c5c !important;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9 !important;
    min-width: 160px;
    -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 30;
  }
  .dropdown-content a {
    text-align: left !important;
    color: black;
    padding: 12px 16px !important;
    text-decoration: none;
    display: block !important;
    margin: 0 !important;
  }
  .dropdown-content a:hover {
    background-color: #f1f1f1 !important;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .video-box {
    width: 100%;
    height: 450px;
    background-color: #000;
  }
  .home-box2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .home-box2 .box1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    width: 50%;
    max-width: 50%;
    margin: 0px;
  }
  .home-box2 .box1 a {
    color: #3c4c5c;
  }
  .sideMenu {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: -2px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    border: #900 2px solid;
    -webkit-transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, -webkit-transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in;
    transition: visibility 0.1s ease-in, opacity 0.07s ease, transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
  }
  .sideMenu-show {
    z-index: 2000;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, -webkit-transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in;
    transition: visibility .15s ease-out, opacity 0.1s ease-in, transform 0.12s ease-in, -webkit-transform 0.12s ease-in;
  }
  .sideMenu-content {
    position: fixed;
    height: 100%;
    top: 0;
    right: -230px;
    background-color: #fff;
    width: 230px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
    transition: visibility 0.05s linear, opacity .1s ease, right .2s ease, -webkit-transform 0.15s;
    transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease;
    transition: visibility 0.05s linear, opacity .1s ease, transform 0.15s, right .2s ease, -webkit-transform 0.15s;
  }
  .sideMenu-content-show {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0px;
    background-color: white;
    width: 230px;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, right 1s ease, -webkit-transform 0.25s;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease;
    transition: visibility 0s linear, opacity 0.75 ease, transform 0.25s, right 1s ease, -webkit-transform 0.25s;
  }
  .sideMenuBrand {
    float: left;
    width: 100px;
    line-height: 1.5rem;
    text-align: left;
    margin: 10px;
    text-transform: uppercase;
    content: 'IBIN';
    color: #333;
    font-family: zwippi-font;
  }
  .sideMenu-close-button {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    background-color: lightgray;
    margin: 10px;
    margin-right: 30px;
  }
  .sideMenu-close-button:hover {
    background-color: #3c4c5c;
    color: #FFF;
  }
  .sideMenu-close-button-general {
    float: right;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 100%;
    background-color: #FFF;
    margin: 3px;
    color: #A5AFBA;
  }
  .sideMenu-close-button-general:hover {
    background-color: #808E9D;
  }
  #sideMenuControlheader {
    margin-top: 42px;
    text-align: center;
    font-size: 24px;
    font-family: zwippi-font;
    text-align: center;
    border-top: 1px rgba(165, 175, 186, 0.5) solid;
  }
  #sideMenuControlheader lu {
    clear: both;
    text-indent: none;
    line-height: 40px;
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
  }
  #sideMenuControlheader li {
    clear: both;
    text-indent: none;
    counter-increment: section;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    -webkit-transition: background .45s ease;
    transition: background .45s ease;
  }
  #sideMenuControlheader li:hover {
    text-indent: none;
    list-style-type: none;
    border-bottom: 1px solid rgba(165, 175, 186, 0.5);
    background: #3c4c5c;
    color: #FFF;
  }
  #sideMenuControlheader li a::before {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    margin-left: 6px;
    content: '';
    font-size: 9px;
    color: #a5afba;
    line-height: 30px;
    display: inline-block;
  }
  #sideMenuControlheader li a {
    text-decoration: none;
    color: #221f1f;
    display: block;
    width: 100%;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
  }
  #sideMenuControlheader li a:hover {
    text-decoration: none;
    color: #fff;
    display: block;
    width: 100%;
  }
  /* Slider */
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }
  /* Icons */
  @font-face {
    font-family: "slick";
    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
  }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  .slick-prev:hover:before, .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: -25px;
  }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
  }
  .slick-prev:before {
    content: "←";
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }
  .slick-next {
    right: -25px;
  }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
  }
  .slick-next:before {
    content: "→";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
  }
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
  }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table;
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .responsive-wrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0px;
  }
  .responsive-wrapper .prevBtn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: block;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: transparent;
    border: 0;
    color: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: 20px;
  }
  .responsive-wrapper .nextBtn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: block;
    font-size: 15px;
    position: absolute;
    right: 0;
    top: 50%;
    background-color: transparent;
    border: 0;
    color: #fff;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-right: 20px;
  }
  .responsive {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    display: block;
    background-color: #ccc;
    margin-top: 5px;
  }
  .responsive .slider {
    display: block;
    width: 100%;
    height: 100vh;
    position: relative;
    padding: 0;
  }
  .responsive .slider div img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .responsive .slider-content {
    bottom: -5px;
    height: 100vh;
    margin: 0;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: 50%;
    text-align: right;
  }
  .responsive .slider-content .slide-text {
    display: block;
    width: 120px;
    position: absolute;
    right: 4px;
    top: 50%;
    line-height: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .responsive .slider-content .slide-text .bt1 {
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .responsive .slider-content .slide-text .bt2 {
    font-size: 16px;
    color: #fff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .responsive .slider-content .slide-text .summary {
    font-size: 11px;
    color: #fff;
  }
  .mobile_art {
    display: block;
  }
  .layoutBy2Art {
    display: none;
  }
  h1 {
    color: #eee;
    font-family: alt-font;
    font-size: 25px;
    padding: 0px;
    text-transform: uppercase;
  }
  h1 a {
    color: #eee;
  }
  h2 {
    color: #3c4c5c;
    font-family: zwippi-light-font;
    font-size: 20px;
  }
  h2 a {
    color: #eee;
  }
  h3 {
    color: #eee;
    font-family: alt-font;
    font-size: 18px;
  }
  h3 a {
    color: #eee;
  }
  h4 {
    color: #eee;
    font-family: zwippi-light-font;
    font-size: 16px;
  }
  .content {
    display: block;
  }
  .content .layoutBy2 {
    width: calc( 100% - 20px);
    padding: 10px;
    margin-bottom: 0px;
  }
  .content .layoutBy2 p {
    line-height: 28px;
  }
  .content .layoutBy2_bottom {
    width: calc( 100% - 20px);
    padding: 10px;
    margin-bottom: 100px;
  }
  .footer {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #3c4c5c;
  }
  .footer .footer-wrapper {
    z-index: 11;
    display: block;
    height: auto;
    margin: 0px auto;
    margin-bottom: 10px;
    max-width: 1400px;
    width: calc( 100% - 20px);
    padding: 10px;
  }
  .footer .footer-wrapper .logo {
    width: 50px;
    height: 25px;
    background: #3c4c5c url(../img/logo_1.jpg) no-repeat center top;
    background-size: 100%;
    margin: 0px;
    display: none;
  }
  .footer .footer-wrapper form {
    border: 1px solid #d4a300;
    padding: 0;
    width: calc( 100% - 130px);
    display: none;
    /* display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex-wrap: wrap-reverse;
          flex-flow: row wrap;
          justify-content: left;
*/
  }
  .footer .footer-wrapper form input[type='email'] {
    border: 0;
    display: inline-block;
    padding: 10px;
    margin: 0;
    width: calc( 75% - 20px);
  }
  .footer .footer-wrapper form button {
    border: 0;
    background-color: #d4a300;
    text-align: center;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: 25%;
  }
  .footer .footer-wrapper .part_1 {
    width: calc( 100% - 20px);
    display: block;
    color: #FFF;
    padding: 10px;
    text-align: center;
  }
  .footer .footer-wrapper .part_1 h3 {
    color: #d4a300;
  }
  .footer .footer-wrapper .part_1 a {
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    font-size: 16;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
  }
  .footer .footer-wrapper .part_1 a:hover {
    background-color: #d4a300;
    font-size: 20px;
  }
  .footer .footer-wrapper .part_2 {
    width: calc( 100% - 22px);
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: block;
    color: #FFF;
    padding: 10px;
    height: auto;
    text-align: center;
  }
  .footer .footer-wrapper .part_2 h3 {
    color: #d4a300;
  }
  .footer .footer-wrapper .part_2 .footermenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
  .footer .footer-wrapper .part_2 .footermenu a {
    display: block;
    width: 20%;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    -webkit-transition: all 0.08s ease;
    transition: all 0.08s ease;
  }
  .footer .footer-wrapper .part_2 .footermenu a:hover {
    color: #d4a300;
    border-top: 1px solid #d4a300;
  }
  .footer .footer-wrapper .part_2 .footermenu a:visited {
    text-decoration: none;
  }
}

/* * * * * * * */
